'use strict';

var sections = {
	// page: require('./page/main'),
	// product: require('./product/main'),
	blog: require('./blog/main')
};

var init = function(actionSection, actionItem) {

	if (sections[actionSection]) {

		console.info('Attempting to init the "' + actionSection + '" section...');

		if (sections[actionSection].init) {

			console.info('The "' + actionSection + '" section module was found, initing now!');
			sections[actionSection].init(actionItem);

		} else {

			console.warn('The "' + actionSection + '" section does not have an init method set up!');
		}

	} else {

		console.warn('The "' + actionSection + '" section does not have a module set up!');

	}

};

module.exports = {
	init: init
};
