'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var triggerCustomEvent = require('trigger-custom-event');

var appDom = require('./app-dom');

var dom;
var opts;
var dfd;
var originalViewportMetaContent;
var isOpen;

var setupDom = function() {

	dom = {};
	dom.viewportMeta = $('meta[name="viewport"]');
	dom.modal = appDom.app.find('#modal');
	dom.modalDynamicContent = dom.modal.find('.modal__dynamic-content');

};

var addEventHandlers = function() {

	dom.modal.on('click', onModalClick);

};

var onModalClick = function(e) {

	var $target = $(e.target);

	if (opts.closeOnOverlayClick) {

		// Make sure the click did not originate from the dynamic content
		if ($target.hasClass('modal__dynamic-content') || !$target.closest('.modal__dynamic-content').length) {

			close();

		}

	} else {

		// Only close if the close button was clicked
		if ($target.closest('.modal__close-btn').length) {

			close();

		}

	}

};

var onKeyDown = function(e) {

	if (e.keyCode === 27) {

		close();

	}

};

var close = function() {

	if (isOpen) {

		isOpen = false;
		setContent('');
		dom.modal.attr('class', 'modal');
		dom.viewportMeta.attr('content', originalViewportMetaContent);
		dfd = null;
		appDom.document.off('.modal');
		triggerCustomEvent('modal_closed');

	}

};

var open = function(options) {

	var modalClasses = 'modal--is-open';
	var defaultOpts = {
		url: '',
		content: '',
		modifiers: [],
		closeOnOverlayClick: false,
		centerContent: true,
		disableZoom: true,
		scroll: true,
		letterbox: true,
		contain: true,
		autoSetContent: true,
		data: {}
	};

	isOpen = true;

	dfd = $.Deferred();
	opts = $.extend(true, defaultOpts, options || {});

	if (opts.centerContent) {

		modalClasses += ' modal--center-content';

	}

	if (opts.scroll) {

		modalClasses += ' modal--scroll';

	}

	if (opts.letterbox) {

		modalClasses += ' modal--letterbox';

	}

	if (opts.contain) {

		modalClasses += ' modal--contain';

	}

	opts.modifiers.forEach(function(modifier) {

		modalClasses += ' modal--' + modifier;

	});

	originalViewportMetaContent = dom.viewportMeta.attr('content');
	dom.viewportMeta.attr('content', originalViewportMetaContent + ', user-scalable=no');

	// If content was passed in, add it to the dom before we visually reveal the modal
	if (opts.content) {

		onModalContentReady({markup: opts.content});

	}

	dom.modal.addClass(modalClasses);

	triggerCustomEvent('modal_opened');

	if (opts.url) {

		$.ajax({
			method: 'POST',
			url: opts.url,
			data: opts.data
		}).done(onModalContentReady);

	}

	appDom.document.on('keydown.modal', onKeyDown);

	return dfd.promise();

};

var setContent = function($content) {

	console.log('set the dynamic content');
	console.log($content);
	console.log(dom.modalDynamicContent.length);

	dom.modalDynamicContent.html($content);

};

var onModalContentReady = function(response) {

	var $content = $(response.markup);

	if (opts.autoSetContent) {

		setContent($content);

	}

	dfd.resolve({
		$content: $content
	});

};

var init = function() {

	setupDom();
	addEventHandlers();

};

module.exports = {
	init: init,
	open: open,
	close: close,
	setContent: setContent
};
