'use strict';

var commentForm = require('./comment-form');
var archiveForm = require('./archive-form');

var init = function() {

	commentForm.init();

	archiveForm.init();

};

module.exports = {
	init: init
};
