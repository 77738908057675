'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var domCache = require('dom-cache');

var getJsonFromUrl = function(url) {

	var query = url || location.search.substr(1);
	var result = {};
	
	query.split("&").forEach(function(part) {
		var item = part.split("=");
		result[item[0]] = decodeURIComponent(item[1]);
	});

	return result;

};

var open = function(url) {

	var urlData = getJsonFromUrl(url);


	var hasExternalUrlParam = typeof urlData.external !== 'undefined' && urlData.external === 'true';
	var isExternalLink = hasExternalUrlParam || url.substr(0, 4) === 'http';

	if (url && isExternalLink) {

		window.open(url);

	} else if (url) {

		window.location = url;

	}

};

var addEventHandlers = function() {

	domCache.document.on('click.container-link', '[data-container-link]', function() {

		console.log('container link event fired');
		console.log($(this).attr('data-container-link'));

		open($(this).attr('data-container-link'));

	});

};

var init = function() {

	console.log('container link inited');

	addEventHandlers();

};

var destroy = function() {

	domCache.document.off('.container-link')

};

module.exports = {
	init: init
};
