'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var objFit = require('obj-fit');

var appDom = require('./app-dom');
var modal = require('./modal');

var objFitInstance;

var onVideoModalClick = function(e) {

	// Prevent linking behavior for anchor tags
	e.preventDefault();

	modal.open({
		content: $(this).attr('data-video-modal'),
		scroll: false
	});

	objFitInstance = objFit.create({
		dataAttributeName: 'video-modal-obj-fit',
		target: '[data-video-modal-obj-fit]'
	});

	appDom.document.one('modal_closed', onModalClosed);

};

var onModalClosed = function() {

	objFitInstance.destroy();
	objFitInstance = null;

};

var addEventHandlers = function() {

	appDom.document.on('click', '[data-video-modal]', onVideoModalClick);

};

var init = function() {

	addEventHandlers();

};

module.exports = {
	init: init
};
