'use strict';

var objFit = require('obj-fit');

var appDom = require('./app-dom');
var utils = require('./utils/main');

var init = function() {

	if (!utils.supports.objectFit && !utils.supports.objectPosition && appDom.app.find('[data-obj-fit]').length) {

		objFit.create();

	}

};

module.exports = {
	init: init
};
