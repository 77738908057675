'use strict';

var domCache = require('dom-cache');

var dom = require('./dom');

var instanceMap = {};

var create = function(formId) {

	return instanceMap[formId] = new Signature(formId);

};

var get = function(formId) {

	return instanceMap[formId];

};

var Signature = function(formId) {

	this.formId = formId;
	this.domInstance = dom.get(formId);

	this.domInstance.form.on({
		'fbWidget-destroy': this.destroy.bind(this)
	});

	// Only inject the font once
	if (this.domInstance.form.find('.fb-signature').length && !domCache.head.find('#fbSignatureFont').length) {

		domCache.head.append('<link id="fbSignatureFont" href="https://fonts.googleapis.com/css?family=Satisfy" rel="stylesheet">');

	}

};

Signature.prototype.destroy = function() {

	delete instanceMap[this.formId];

};

module.exports = {
	create: create,
	get: get
};
