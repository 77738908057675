'use strict';

var domCache = require('dom-cache');
var notify = require('notify');

var dom = require('./dom');
var settings = require('./settings');

var instanceMap = {};

var create = function(formId) {

	return instanceMap[formId] = new OutdatedMsg(formId);

};

var get = function(formId) {

	return instanceMap[formId];

};

var OutdatedMsg = function(formId) {

	this.formId = formId;
	this.domInstance = dom.get(formId);
	this.settingsInstance = settings.get(formId);

	this.domInstance.form.on('fbWidget-destroy', this.destroy.bind(this));

};

OutdatedMsg.prototype.destroy = function() {

	delete instanceMap[this.formId];

};

OutdatedMsg.prototype.show = function(msg) {

	this.domInstance.disableSubmit();

	notify.show({
		target: this.domInstance.submitBtn,
		msg: msg,
		container: this.settingsInstance.notifyContainer === 'target' ? this.domInstance.submitBtnContainer : this.settingsInstance.notifyContainer,
		scrollContainer: this.settingsInstance.notifyScrollContainer
	});

	domCache.document.one('click', notify.hide);

};

module.exports = {
	create: create,
	get: get
};
