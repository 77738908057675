// Required global dependencies:
//	1. jQuery UI
//		- v1.11.2
//		- core.css, datepicker.css
//		- core.js, datepicker.js
//	2. Modernizr
//		- v2.8.3
//		- http://modernizr.com/download/#-inputtypes-touch-cssclasses-teststyles-prefixes
//
//	This module comes bundled with the notify module (js and css).

'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var placeholderToggle = require('placeholder-toggle');
var scrollElement = require('scroll-element');

var dom = require('./dom');
var settings = require('./settings');
var datepicker = require('./datepicker');
var selectmenu = require('./selectmenu');
var time = require('./time');
var rating = require('./rating');
var fileupload = require('./fileupload');
var familyfriends = require('./familyfriends');
var option = require('./option');
var embed = require('./embed');
var layout = require('./layout');
var validate = require('./validate');
var countryStateSync = require('./country-state-sync');
var payment = require('./payment');
var signature = require('./signature');
var address = require('./address');
var fullname = require('./fullname');
var outdatedMsg = require('./outdated-msg');
var mutationWatch = require('./mutation-watch');

var instanceMap = {};

var create = function(formId, opts) {

	return instanceMap[formId] = new FormWidget(formId, opts);

};

var get = function(formId) {

	return instanceMap[formId];

};

var FormWidget = function(formId, opts) {

	var defaults = {
		forceOneColMode: false,
		reveal: true,
		notifyContainer: '',
		notifyScrollContainer: '',
		icons: {
			base: '',
			plus: 'plus',
			minus: 'minus',
			calendar: 'calendar',
			clock: 'clock',
			envelope: 'envelope',
			user: 'user',
			phone: 'phone',
			mapMarker: 'map-marker',
			birthdayCake: 'birthday-cake',
			building: 'building',
			facebook: 'facebook',
			twitter: 'twitter',
			users: 'users',
			text: 'text',
			pencil: 'pencil',
			creditCard: 'credit-card'
		}
	};

	// Only init the placeholderToggle system module one time, when the first formWidget instance is created
	if (!Object.keys(instanceMap).length) {

		placeholderToggle.init();

	}

	this.formId = formId;
	this.opts = $.extend(true, {}, defaults, opts);

	this.domInstance = dom.create(formId);
	settings.create(formId, this.opts.notifyContainer, this.opts.notifyScrollContainer);
	selectmenu.create(formId);
	option.create(formId);
	outdatedMsg.create(formId);
	datepicker.create(formId);
	time.create(formId);
	rating.create(formId);
	fileupload.create(formId);
	familyfriends.create(formId);
	embed.create(formId);
	payment.create(formId);
	layout.create(formId, this.opts.forceOneColMode);
	signature.create(formId);
	countryStateSync.create(formId);
	address.create(formId);
	fullname.create(formId);
	validate.create(formId);
	mutationWatch.create(formId);

	this.submissionHeaderText = this.domInstance.submissionHeader.text();
	this.submissionMsgText = this.domInstance.submissionMsg.text();
	this.title = this.domInstance.title.text();
	this.form = this.domInstance.form;
	this.isClosed = this.domInstance.form.hasClass('fb-form--closed');

	this.domInstance.form
		.on('click', 'label', this.onLabelClick.bind(this))
		.on('keypress', this.blockEnterKeyFromSubmittingForm.bind(this));

	// Use passed in icons
	// plus
	this.form.find('.pbi-plus')
		.removeClass('pbi-plus')
		.addClass(this.opts.icons.base + ' ' + this.opts.icons.base + '--' + this.opts.icons.plus);
	// minus
	this.form.find('.pbi-minus')
		.removeClass('.pbi-minus')
		.addClass(this.opts.icons.base + ' ' + this.opts.icons.base + '--' + this.opts.icons.minus);
	// calendar
	this.form.find('.fb-calendar-icon').addClass(this.opts.icons.base + ' ' + this.opts.icons.base + '--' + this.opts.icons.calendar);
	// envelope
	this.form.find('.fb-envelope-icon').addClass(this.opts.icons.base + ' ' + this.opts.icons.base + '--' + this.opts.icons.envelope);
	// user
	this.form.find('.fb-user-icon').addClass(this.opts.icons.base + ' ' + this.opts.icons.base + '--' + this.opts.icons.user);
	// phone
	this.form.find('.fb-phone-icon').addClass(this.opts.icons.base + ' ' + this.opts.icons.base + '--' + this.opts.icons.phone);
	// map marker
	this.form.find('.fb-map-marker-icon').addClass(this.opts.icons.base + ' ' + this.opts.icons.base + '--' + this.opts.icons.mapMarker);
	// birthday cake
	this.form.find('.fb-birthday-cake-icon').addClass(this.opts.icons.base + ' ' + this.opts.icons.base + '--' + this.opts.icons.birthdayCake);
	// building
	this.form.find('.fb-building-icon').addClass(this.opts.icons.base + ' ' + this.opts.icons.base + '--' + this.opts.icons.building);
	// facebook
	this.form.find('.fb-facebook-icon').addClass(this.opts.icons.base + ' ' + this.opts.icons.base + '--' + this.opts.icons.facebook);
	// twitter
	this.form.find('.fb-twitter-icon').addClass(this.opts.icons.base + ' ' + this.opts.icons.base + '--' + this.opts.icons.twitter);
	// users
	this.form.find('.fb-users-icon').addClass(this.opts.icons.base + ' ' + this.opts.icons.base + '--' + this.opts.icons.users);
	// text
	this.form.find('.fb-text-icon').addClass(this.opts.icons.base + ' ' + this.opts.icons.base + '--' + this.opts.icons.text);
	// clock
	this.form.find('.fb-clock-icon').addClass(this.opts.icons.base + ' ' + this.opts.icons.base + '--' + this.opts.icons.clock);
	// pencil
	this.form.find('.fb-pencil-icon').addClass(this.opts.icons.base + ' ' + this.opts.icons.base + '--' + this.opts.icons.pencil);
	// credit card
	this.form.find('.fb-credit-card-icon').addClass(this.opts.icons.base + ' ' + this.opts.icons.base + '--' + this.opts.icons.creditCard);

	this.domInstance.triggerFormEvent('fbWidget-inited');

	if (this.opts.reveal) {

		this.reveal();

	}

};

FormWidget.prototype.destroy = function() {

	this.domInstance.triggerFormEvent('fbWidget-destroy');
	delete instanceMap[this.formId];

};

FormWidget.prototype.blockEnterKeyFromSubmittingForm = function(e) {

	if (e.which === 13 && e.target.tagName !== 'TEXTAREA') {

		e.preventDefault();

	}

};

// Hijack label clicks so we can focus/toggle their respective inputs without having to change the underlying markup to make all ids truly unique on the page (in order to support multiple forms on one page)
FormWidget.prototype.onLabelClick = function(e) {

	var $label = $(e.target).closest('label');
	var forId = $label.attr('for');
	var $target = this.domInstance.form.find('#' + forId);
	var $clickTarget = $(e.target);

	if (!$clickTarget.is('a')) {

		e.preventDefault();

		$target.focus();

		if (!$target.prop('disabled')) {

			if ($target.is('[type="radio"]')) {

				$target.prop('checked', true);
				$target.change();

			} else if ($target.is('[type="checkbox"]')) {

				$target.prop('checked', !$target.prop('checked'));
				$target.change();

			}

		}

	}

};

FormWidget.prototype.reveal = function() {

	this.domInstance.form.removeClass('fb-intro-running');
	this.domInstance.form.addClass('fb-intro-complete');

};

FormWidget.prototype.reset = function() {

	this.domInstance.form.trigger('reset');
	this.enable();

};

FormWidget.prototype.disable = function() {

	this.domInstance.disableSubmit();

};

FormWidget.prototype.enable = function() {

	this.domInstance.enableSubmit();

};

FormWidget.prototype.showSubmission = function(paymentReceiptUrl, documentViewUrl) {

	if (paymentReceiptUrl) {

		this.domInstance.submissionReceiptBtn
			.attr('href', paymentReceiptUrl)
			.text('View Receipt');

	}

	this.domInstance.submissionDocumentViewBtn.attr('href', documentViewUrl);
	this.domInstance.form.addClass('fb-show-submission');
	scrollElement();
	this.domInstance.triggerFormEvent('fbWidget-submission-shown');

};

module.exports = {
	create: create,
	get: get
};
