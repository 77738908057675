'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var formWidget = require('form-widget-2.0.0');
var triggerCustomEvent = require('trigger-custom-event');

var utils = require('./utils/main');
var appDom = require('./app-dom');
var modal = require('./modal');

var triggerMutationEvent = function() {

	triggerCustomEvent('form_mutation');

};

var onFormSubmissionSuccess = function(e, data) {

	var $block = utils.dom.closestBlock(this.form);
	var isFormInBlock = !!$block.length;
	var $formSubmission;
	var $receiptBtn;

	if (isFormInBlock) {

		$formSubmission = $($block.find('.form-submission')[0].outerHTML);

	} else {

		$formSubmission = $(this.form.prev().find('.form-submission')[0].outerHTML);

	}

	$receiptBtn = $formSubmission.find('.form-submission__receipt-btn');

	// Add in the links for the receipt button (if applicable) and the submission button
	if (data.paymentReceiptUrl) {

		$receiptBtn.attr('href', data.paymentReceiptUrl);

	} else {

		$receiptBtn.remove();

	}

	$formSubmission.find('.form-submission__submission-btn').attr('href', data.documentViewUrl);

	modal.open({
		content: $formSubmission,
		closeOnOverlayClick: true
	});

	this.reset();

};

var onFormSubmissionBlocked = function(e, data) {

	// console.log(data);

	this.form.html(data.markup);
	triggerMutationEvent();

};

var init = function() {

	var $formWidgets = appDom.app.find('.fb-form');
	var blogWidgetIdCnt = 0;

	$formWidgets.each(function(i) {

		var $formWidget = $formWidgets.eq(i);
		var $block = utils.dom.closestBlock($formWidget);
		var isFormInBlock = !!$block.length;
		var blockType;
		var blockId;
		var formId;
		var formWidgetInstance;

		if (isFormInBlock) {

			blockType = utils.dom.getBlockType($block);
			blockId = utils.dom.getBlockId($block);
			formId = 'form_' + blockId;

		} else {

			// This form is part of the blog widgets, which do not actually live inside of a block
			formId = 'blogWidgetForm_' + blogWidgetIdCnt++;

		}

		// Assign a unique ID to the form, this ID will also be passed in when we create the formWidget instance so the formWidget can manage all it's instances
		$formWidget.attr('id', formId);

		formWidgetInstance = formWidget.create(formId, {
			// We'll manually reveal the form after we're done manipulating it
			reveal: false,
			icons: {
				base: 'sites-icon'
			}
		});


		// If the form is closed, add a class to the block itself, so we can better style the button that appears
		if (isFormInBlock && formWidgetInstance.isClosed) {

			$block.addClass(blockType + '-block--closed');

		}

		if (blockType === 'basic-form') {

			// Increase the default size for textboxes in a basic form. Since basic forms are footer blocks, their content width is set to auto, which the form widget (and all of it's internal responsive rules) was never designed to handle. Adding a little extra default size increases the width a little without messing with any of the internal responsive settings.
			formWidgetInstance.form.find('input[type="text"],input[type="email"],input[type="date"]').attr('size', '25');

		}

		// Now that we're done making tweaks, reveal the form
		formWidgetInstance.reveal();

		formWidgetInstance.form
			.on('fbWidget-mutation', triggerMutationEvent)
			.on('fbWidget-form-submit-success', onFormSubmissionSuccess.bind(formWidgetInstance))
			.on('fbWidget-form-expired fbWidget-form-at-submission-limit', onFormSubmissionBlocked.bind(this));

	});

};

module.exports = {
	init: init
};
