'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var triggerCustomEvent = require('trigger-custom-event');

var dom;

var setupDom = function() {

	dom = {};
	dom.viewCartLink = $('.js-view-cart');

};

var addEventHandlers = function() {

	dom.viewCartLink.on('click', onViewCartLinkClicked);

};

var onViewCartLinkClicked = function(e) {

	e.preventDefault();

	triggerCustomEvent('view_cart_link_clicked');

};

var init = function() {

	setupDom();

	addEventHandlers();

};

module.exports = {
	init: init
};
