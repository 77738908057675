'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var notify = require('notify');
var triggerCustomEvent = require('trigger-custom-event');

var utils = require('../../utils/main');
var appDom = require('../../app-dom');

var addEventHandlers = function() {

	console.log('ADD SCHEDULER DETAIL FORM EVENT HANDLERS!');

	appDom.document.off('.scheduler_detail');
	appDom.document.on('change.scheduler_detail', '.scheduler-detail__option-select', onServiceOptionChanged);
	appDom.document.on('click.scheduler_detail', '.js-schedule-btn', onScheduleBtnClicked);
	appDom.document.on('item_added_to_cart.scheduler_detail', onItemAddedToCart);

};

var onScheduleBtnClicked = function() {

	console.log('onScheduleBtnClicked called');

	var $btn = $(this);

	$.ajax({
		url: utils.getUrl('scheduler.processDetailForm'),
		method: 'post',
		data: $btn.parents('.scheduler-detail__service-form').serialize()
	}).done(function(response) {

		onScheduleBtnClickedDone(response, $btn);

	});

};

var onScheduleBtnClickedDone = function(response, $btn) {

	if (response.status.code) {

		notify.hide();

		triggerCustomEvent('schedule_button_clicked', {
			data: {
				$serviceForm: $btn.parents('.scheduler-detail__service-form')
			}
		});

	} else {

		notify.show({
			target: $btn.parents('.scheduler-detail__service-form').find('[name="' + response.target + '"]'),
			msg: response.status.msg
		});

	}

};

var onItemAddedToCart = function() {

	notify.hide();

	resetServiceForm();

};

var resetServiceForm = function() {

	var $serviceForms = appDom.document.find('.scheduler-detail__service-form');
	var $selectBoxes = $serviceForms.find('select');
	var $textInputs = $serviceForms.find('input[type="text"]');
	var $textareas = $serviceForms.find('textarea');

	$selectBoxes.each(function(i, el) {

		$(el).find('option:eq(0)').attr('selected', 'selected');

	});

	$textInputs.each(function(i, el) {

		$(el).val('');

	});

	$textareas.each(function(i, el) {

		$(el).val('');

	});

	$serviceForms.each(function() {

		loadServiceForm(false, $(this));

	});

};

var onServiceOptionChanged = function() {

	var $option = $(this);
	var $serviceForm = $option.parents('.scheduler-detail__service-form');

	notify.hide();

	loadServiceForm(false, $serviceForm);

};

var loadServiceForm = function(isInitialLoad, $serviceForm) {

	isInitialLoad = typeof isInitialLoad !== 'undefined' ? isInitialLoad : false;

	if (isInitialLoad) {

		console.log('load em up!');

		appDom.document.find('.scheduler-detail__service-form').each(function(i, el) {

			var $serviceForm = $(el);

			$.ajax({
				url: utils.getUrl('scheduler.detailForm'),
				method: 'POST',
				data: $serviceForm.serialize() + '&isInitialLoad=' + isInitialLoad
			}).done(function(response) {

				onLoadServiceFormDone(response, $serviceForm);

			});

		});

	} else {

		$.ajax({
			url: utils.getUrl('scheduler.detailForm'),
			method: 'POST',
			data: $serviceForm.serialize() + '&isInitialLoad=' + isInitialLoad
		}).done(function(response) {

			onLoadServiceFormDone(response, $serviceForm);

		});

	}

};

var onLoadServiceFormDone = function(response, $serviceForm) {

	if (response.status) {

		$serviceForm.filter('[data-item-id=' + response.itemId + ']').html(response.markup);

		addEventHandlers();

	}

};

var init = function() {

	console.log('initialize scheduler detail');

	loadServiceForm(true);

};

module.exports = {
	init: init
};
