'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var notify = require('notify');
var triggerCustomEvent = require('trigger-custom-event');


var dom;

var setupDom = function() {

	dom = {};
	dom.ajaxForms = $('form[data-action]');

};

var addEventHandlers = function() {

	dom.ajaxForms.on('submit', onFormSubmit);

};

var onFormSubmitDone = function(response, $form) {

	// default the "target" field to the first visible
	var $target = $form.find('input:visible').eq(0);

	// if target was explicitly provided, use the provided target
	if (response.target) {
		$target = $('[name="' + response.target + '"]');
	}

	if (response.status.code) {

		if ($form.attr('data-success')) {

			triggerCustomEvent('ajax_form_submission_success');

			window.location = $form.attr('data-success');

		}

	} else {

		$target.focus();

		notify.show({
			msg: response.status.msg,
			target: $target,
			container: $form,
			closeOnClick: true
		});

		triggerCustomEvent('ajax_form_submission_failed');

	}

};

var onFormSubmit = function(e) {

	var $form = $(this);

	e.preventDefault();

	$.ajax({
		url: $form.attr('data-action'),
		type: 'post',
		data: $form.serialize()
	}).done(function(res) {
		onFormSubmitDone(res, $form);
	});

};

var init = function() {

	setupDom();

	addEventHandlers();

};

module.exports = {
	init: init
};
