'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var domCache = require('./app-dom');
var triggerCustomEvent = require('trigger-custom-event');

var dom;

var setupDom = function() {

	dom = {};

};

var addEventHandlers = function() {

	domCache.document.on('click', '.cart__remove-coupon-btn', onRemoveCouponBtnClicked);

};

var onRemoveCouponBtnClicked = function(e) {

	e.preventDefault();

	$(this).text('loading...');

	$.ajax({
		url: '/index.cfm?action=cart.removeCoupon',
		method: 'POST',
		dataType: 'JSON'
	}).done(onRemoveCouponBtnClickedDone);

};

var onRemoveCouponBtnClickedDone = function(response) {

	triggerCustomEvent('coupon_removed');

};

var init = function() {

	setupDom();

	addEventHandlers();

};

module.exports = {
	init: init
};