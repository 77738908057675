'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var notify = require('notify');
var triggerCustomEvent = require('trigger-custom-event');
var pbizServer = (typeof window !== "undefined" ? window.pbizServer : typeof global !== "undefined" ? global.pbizServer : null);

var appDom = require('./app-dom');
var dom;
var addToCartText;

var setupDom = function() {

	dom = {};
	dom.productForm = $('.product-detail__form');

};

var addEventHandlers = function() {

	appDom.document.on('click', '.js-add-to-cart', onAddToCartLinkClicked);
	appDom.document.on('click', '.product-detail__add-to-cart-btn--is-disabled', onDisabledAddToCartClicked);

};

var onDisabledAddToCartClicked = function(e) {

	e.preventDefault();

};

var grabFormData = function(formArray) {

	var returnArray = {};

	for (var i = 0; i < formArray.length; i++){
		returnArray[formArray[i]['name']] = formArray[i]['value'];
	}

	return returnArray;

};

var onAddToCartLinkClicked = function(e) {

	var $link = $(this);

	dom.productForm = $link.parents('.product-detail__form');

	var data = grabFormData(dom.productForm.serializeArray());

	addToCartText = $link.text();

	$link.text(pbizServer.content['Generic.Loading']);

	// add some custom goodies
	data['sku'] = $link.attr('data-sku') ? $link.attr('data-sku') : '';
	data['sourceUrl'] = $link.attr('data-item-url');

	e.preventDefault();

	$.ajax({
		url: '/index.cfm?action=cart.add',
		method: 'POST',
		dataType: 'JSON',
		data: data
	}).done(onAddToCartLinkClickedDone);

};

var onAddToCartLinkClickedDone = function(response) {

	if (response.status.code) {
		
		triggerCustomEvent('item_added_to_cart');

	} else {

		dom.productForm.find('.product-detail__add-to-cart-btn').text(addToCartText);
		
		notify.show({
			target: $('[name="' + response.target + '"]'),
			msg: response.status.msg
		});

	}

};

var init = function() {

	// setupDom();

	// addEventHandlers();

};

module.exports = {
	init: init
};