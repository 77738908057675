'use strict';

var appDom = require('../../app-dom');
var detail = require('./detail');
var calendar = require('./calendar');
var bookingForm = require('./booking-form');

var init = function(/*actionItem*/) {

	console.log('main scheduler init');
	console.log(appDom.document.find('.scheduler-detail').length);

	if (appDom.document.find('.scheduler-detail').length) {

		detail.init();

		calendar.init();

		bookingForm.init();

	}

};

module.exports = {
	init: init
};
