'use strict';

var appDom = require('../../app-dom');
var detail = require('./detail');

var init = function(/*actionItem*/) {

	console.log('service-detail?');
	console.log(appDom.document.find('.service-detail'));

	if (appDom.document.find('.service-detail').length) {

		console.log('init the service detail stuff!');

		detail.init();

	}

};

module.exports = {
	init: init
};
