var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var appDom = require('./app-dom');
var utils = require('./utils/main');
var modal = require('./modal');
var galleryComponent = require('./gallery/main');

var fullScreenGalleryInstance;

var onModalClosed = function() {

	fullScreenGalleryInstance.destroy();
	fullScreenGalleryInstance = null;

};

var onLaunchFullScreenGalleryClick = function() {

	var $this = $(this);
	var $block = utils.dom.closestBlock($this);
	var isMockedBlock = utils.dom.isMockedBlock($block);
	var data = {
		blockType: utils.dom.getBlockType($block),
		targetSequence: parseInt($this.attr('data-gallery-item-sequence'), 10)
	};

	if (isMockedBlock) {

		// Grab data to support mocked product detail block or mocked blog post images block
		data.resourceIds = utils.dom.getBlockResourceIds($block);
		data.blogPostId = utils.dom.getBlockBlogPostId($block);
		data.blogPostInstanceId = utils.dom.getBlockBlogPostInstanceId($block);

	} else {

		data.blockId = utils.dom.getBlockId($block);

	}

	modal.open({
		url: utils.getUrl('block.loadFullScreenGallery'),
		data: data,
		// Don't let the modal inject the gallery when the markup comes back because we want the gallery component to only initially render a subset of the items
		autoSetContent: false,
		scroll: false,
		letterbox: false,
		contain: false,
	}).done(function(data) {

		fullScreenGalleryInstance = galleryComponent.create(data.$content);
		appDom.document.one('modal_closed', onModalClosed);

	});

};

var addEventHandlers = function() {

	appDom.content.on('click', '.js-launch-full-screen-gallery', onLaunchFullScreenGalleryClick);

};

var init = function() {

	appDom.content.find('.gallery').each(function(i, el) {

		galleryComponent.create($(el));

	});

	addEventHandlers();

};

module.exports = {
	init: init
};
