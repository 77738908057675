'use strict';

var closestBlock = function($el) {

	return $el.closest('.block');

};

var getClosestBlockId = function($el) {

	return getBlockId(closestBlock($el));

};

var getBlockId = function($block) {

	return $block.attr('data-block-id');

};

var getBlockType = function($block) {

	return $block.attr('data-block-type-key');

};

var getBlockResourceType = function($block) {

	return $block.attr('data-block-resource-type-key');

};

var getBlockResourceIds = function($block) {

	return $block.attr('data-block-resource-ids');

};

var getBlockLayoutKey = function($block) {

	return $block.attr('data-block-layout-key');

};

var getBlockBlogPostId = function($block) {

	return $block.attr('data-block-blog-post-id');

};

var getBlockBlogPostInstanceId = function($block) {

	return $block.attr('data-block-blog-post-instance-id');

};

var getBlockFriendlyName = function($block) {

	return $block.attr('data-block-friendly-name');

};

var isMockedBlock = function($block) {

	return typeof $block.attr('data-mocked-block') !== 'undefined';

};

var findGrid = function($el) {

	return $el.find('.grid');

};

var closestGrid = function($el) {

	return $el.closest('.grid');

};

var getGridGutter = function($grid) {

	return $grid.attr('data-grid-gutter');

};

var getGridGutterInt = function($grid) {

	return parseInt($grid.css('marginTop'), 10) * -1;

};

var findCell = function($grid) {

	return $grid.find('.grid__cell');

};

var findChildCell = function($grid) {

	return $grid.children('.grid__cell');

};

var findLastItem = function($el) {

	return $el.find('.last-item');

};

var findAspectRatio = function($el) {

	return $el.find('.aspect-ratio');

};

var findAspectRatioImg = function($el) {

	return $el.find('.aspect-ratio__img');

};

var getAspectRatioPadding = function($aspectRatio) {

	return parseFloat($aspectRatio[0].style.paddingBottom);

};

var findPinboardLayout = function($el) {

	return $el.find('.pinboard-layout');

};

var findPinboardItem = function($pinboard) {

	return $pinboard.find('[data-pinboard-item-index]');

};

var findPinboardCol = function($pinboard) {

	return $pinboard.find('[data-pinboard-col]');

};

var closestPinboardCol = function($el) {

	return $el.closest('[data-pinboard-col]');

};

var getPinboardItemIndex = function(pinboardItem) {

	return parseInt(pinboardItem.getAttribute('data-pinboard-item-index'), 10);

};

var getPinboardCol = function($pinboardCol) {

	return parseInt($pinboardCol.attr('data-pinboard-col'), 10);

};

module.exports = {
	closestBlock: closestBlock,
	getClosestBlockId: getClosestBlockId,
	getBlockType: getBlockType,
	getBlockId: getBlockId,
	getBlockResourceType: getBlockResourceType,
	getBlockResourceIds: getBlockResourceIds,
	getBlockLayoutKey: getBlockLayoutKey,
	getBlockBlogPostId: getBlockBlogPostId,
	getBlockBlogPostInstanceId: getBlockBlogPostInstanceId,
	getBlockFriendlyName: getBlockFriendlyName,
	isMockedBlock: isMockedBlock,
	findGrid: findGrid,
	closestGrid: closestGrid,
	getGridGutter: getGridGutter,
	getGridGutterInt: getGridGutterInt,
	findCell: findCell,
	findChildCell: findChildCell,
	findLastItem: findLastItem,
	findAspectRatio: findAspectRatio,
	findAspectRatioImg: findAspectRatioImg,
	getAspectRatioPadding: getAspectRatioPadding,
	findPinboardLayout: findPinboardLayout,
	findPinboardItem: findPinboardItem,
	findPinboardCol: findPinboardCol,
	closestPinboardCol: closestPinboardCol,
	getPinboardCol: getPinboardCol,
	getPinboardItemIndex: getPinboardItemIndex
};
