'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var dom = require('./dom');

var instanceMap = {};

var create = function(formId) {

	return instanceMap[formId] = new Rating(formId);

};

var get = function(formId) {

	return instanceMap[formId];

};

var Rating = function(formId) {

	this.formId = formId;
	this.domInstance = dom.get(formId);
	this.$ratingFields = this.domInstance.inputContainer.find('.fb-rating-type');

	this.domInstance.form
		.on({
			'fbWidget-window-resize': this.onWindowResize.bind(this),
			'fbWidget-destroy': this.destroy.bind(this)
		})
		.on('change', '.fb-rating-type select', this.onSelectChange.bind(this))
		.on('click', '.fb-rating-expanded label', this.onRadioClick.bind(this));

};

Rating.prototype.breakPoints = {
	// Once the form is at least this wide, expand rating fields.
	expandRating: 700
};

Rating.prototype.destroy = function() {

	delete instanceMap[this.formId];

};

Rating.prototype.isElementRatingRadio = function($element) {

	return $element.is('input[name^="rating"]');

};

Rating.prototype.isElementRatingField = function($element) {

	return !!$element.closest('.fb-rating-type').length;

};

Rating.prototype.isRatingType = function($col) {

	return $col.hasClass('fb-rating-type');

};

Rating.prototype.getVal = function($col) {

	// Just parse the compact UI since it's easier to work with and will
	// be in sync with the expanded UI.
	var $els = this.domInstance.getFieldInputs($col.find('.fb-rating-compact-wrapper'));
	var name = this.domInstance.getFieldTitleText($col);
	var vals = [];

	$els.each(function(i) {

		var $el = $els.eq(i);

		vals.push({
			name: this.domInstance.getNestedFieldTitleText($el),
			value: $el.val()
		});

	}.bind(this));

	return {
		name: name,
		value: vals
	};

};

Rating.prototype.onWindowResize = function(e, data) {

	var newLayout = false;
	var formWidth = data.formWidth;
	var ratingCompactClass = 'fb-rating-compact';
	var ratingExpandedClass = 'fb-rating-expanded';

	if (formWidth >= this.breakPoints.expandRating) {

		if (this.$ratingFields.hasClass(ratingCompactClass)) {

			newLayout = true;

		}

		this.$ratingFields.removeClass(ratingCompactClass);
		this.$ratingFields.addClass(ratingExpandedClass);

		if (newLayout) {

			this.domInstance.triggerFormEvent('fbWidget-rating-layout-adjusted', {
				layout: 'expanded'
			});

		}

	} else {

		if (this.$ratingFields.hasClass(ratingExpandedClass)) {

			newLayout = true;

		}

		this.$ratingFields.removeClass(ratingExpandedClass);
		this.$ratingFields.addClass(ratingCompactClass);

		if (newLayout) {

			this.domInstance.triggerFormEvent('fbWidget-rating-layout-adjusted', {
				layout: 'compact'
			});

		}

	}

};

Rating.prototype.onRadioClick = function(e) {

	var $radio = $(e.target).next();
	var idx = +$radio.attr('data-dropdown-idx');
	// The group name of the radio button matches the ID of the select box.
	var name = $radio.attr('name');
	var $select = this.domInstance.form.find('#' + name);

	// Change the selected option of the native select box to match
	// the radio button choice.
	$select.prop('selectedIndex', idx);

	this.domInstance.triggerFormEvent('fbWidget-rating-radio-clicked', {
		$select: $select
	});

};

Rating.prototype.onSelectChange = function(e) {

	var $select = $(e.target).closest('.fb-rating-type select');
	// The ID of the select box matches the ID of the radio button, without
	// the answer index.
	var selectId = $select.attr('id');
	// Offset for the dummy 'Select' option.
	var selectedIndex = $select[0].selectedIndex - 1;

	if (selectedIndex >= 0) {

		// Change the radio button choice to match the select box.
		this.domInstance.form.find('#' + selectId + '-' + selectedIndex).prop('checked', true);

	} else {

		// The dummy 'Select' option was chosen. Uncheck all radio buttons
		// for this question.

		this.domInstance.form.find('[name="' + selectId + '"]').prop('checked', false);

	}

};

module.exports = {
	create: create,
	get: get
};
