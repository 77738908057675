'use strict';

var isInited = false;
var resolvePromise;
var rejectPromise;
const readyPromise = new Promise(function (resolve, reject) {
	resolvePromise = resolve;
	rejectPromise = reject;
});

const init = function (languageCode) {

	if (!isInited) {

		isInited = true;

		// Default language to english
		languageCode = languageCode || 'en';

		window.pbizRecaptchaReady = function () {

			console.log('recaptcha resources are ready!');

			resolvePromise();

		};

		const recaptchaScript = document.createElement('script');

		recaptchaScript.async = true;
		recaptchaScript.defer = true;
		recaptchaScript.src = 'https://www.google.com/recaptcha/api.js?onload=pbizRecaptchaReady&render=explicit&hl=' + languageCode;
		recaptchaScript.onerror = function() {
			rejectPromise(new Error('The google recaptcha script could not be loaded.'));
		};

		document.head.insertAdjacentElement('beforeend', recaptchaScript);

	}

	return readyPromise;

};

module.exports = {
	init: init
};
