'use strict';

var focalPoint = require('focal-point-3.0.0');

var appDom = require('./app-dom');

var focalPointInstance;

var addEventHandlers = function() {

	appDom.document
		.on('grid_items_added', function() {

			focalPointInstance.refresh();
			focalPointInstance.refreshParallax();

		})
		.on('form_mutation', focalPointInstance.refreshParallax);

};

var init = function() {

	if (appDom.app.find('[data-focal-point]').length) {

		// We have at least one image that uses focal point

		// Set up focal point for any image with the default data-focal-point attribute
		focalPointInstance = focalPoint({
			// Turning off throttling because this instance could be for text+image parallax
			resizeThrottle: 0
		});

		addEventHandlers();

	}

};

module.exports = {
	init: init
};
