'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var notify = require('notify');
var triggerCustomEvent = require('trigger-custom-event');
var pbizServer = (typeof window !== "undefined" ? window.pbizServer : typeof global !== "undefined" ? global.pbizServer : null);

var appDom = require('../../app-dom');

var dom;
var addToCartText;

var setupDom = function() {

	dom = {};
	dom.productDetail = $('.product-detail');
	dom.form = dom.productDetail.find('.product-detail__form');

};

var addEventHandlers = function() {

	console.log('add event handlers called');

	dom.productDetail.off('.product_detail');
	dom.productDetail.on('change.product_detail', '.product-detail__option', onProductOptionChanged);
	dom.productDetail.on('change.product_detail', '.product-detail__qty', onQtyChanged);
	dom.productDetail.on('click.product_detail', '.js-add-to-cart', onAddToCartBtnClicked);

	appDom.document.off('.product_detail');
	appDom.document.on('cart_item_qty_changed.product_detail', onQtyChanged);

};

var grabFormData = function(formArray) {

	var returnArray = {};

	for (var i = 0; i < formArray.length; i++){
		returnArray[formArray[i]['name']] = formArray[i]['value'];
	}

	return returnArray;

};

var onAddToCartBtnClicked = function(e) {

	var $btn = $(this);

	dom.form = $btn.parents('.product-detail__form');

	var data = grabFormData(dom.form.serializeArray());

	addToCartText = $btn.text();

	$btn.text(pbizServer.content['Generic.Loading']);

	// add some custom goodies
	data['sku'] = $btn.attr('data-sku') ? $btn.attr('data-sku') : '';
	data['sourceUrl'] = $btn.attr('data-item-url');

	e.preventDefault();

	$.ajax({
		url: '/index.cfm?action=cart.add',
		method: 'POST',
		dataType: 'JSON',
		data: data
	}).done(onAddToCartLinkClickedDone);

};


var onAddToCartLinkClickedDone = function(response) {

	if (response.status.code) {

		triggerCustomEvent('item_added_to_cart');

		onItemAddedToCart();

	} else {

		dom.form.find('.product-detail__add-to-cart-btn').text(addToCartText);

		notify.show({
			target: $('[name="' + response.target + '"]'),
			msg: response.status.msg,
			closeOnClick: true
		});

	}

};


var onItemAddedToCart = function() {

	notify.hide();

	resetProductForm();

};

var resetProductForm = function() {

	console.log('reset the product form');
	console.log(dom.form.length);

	var $selectBoxes = dom.form.find('select');
	var $textInputs = dom.form.find('input[type="text"]');
	var $textareas = dom.form.find('textarea');
	var $addToCartBtn = dom.form.find('.product-detail__add-to-cart-btn');

	$selectBoxes.each(function(i, el) {

		$(el).find('option:eq(0)').attr('selected', 'selected');

	});

	$textInputs.each(function(i, el) {

		$(el).val('');

	});

	$textareas.each(function(i, el) {

		$(el).val('');

	});

	loadProductForm();

};

var onProductOptionChanged = function() {
	
	var $selectedOption = $(this).find('option:selected');

	dom.form = $selectedOption.parents('.product-detail__form');

	notify.hide();

	loadProductForm();

	triggerCustomEvent('product_option_changed', {
		data: {
			$option: $selectedOption,
			imgId: $selectedOption.attr('data-img-id')
		}
	});

};

var onQtyChanged = function() {

	console.log('detail-form qty changed yo!');
	console.log($(this).parents('.product-detail__form').length);

	dom.form = $(this).parents('.product-detail__form');

	if (!dom.form.length) {
		dom.form = dom.productDetail.find('.product-detail__form');
	}

	notify.hide();

	loadProductForm();

};

var loadProductForm = function(isInitialLoad) {

	console.log('LOAD THE PRODUCT FORM!!!');
	console.log(dom.form.length);
	console.log(dom.form);

	isInitialLoad = typeof isInitialLoad !== 'undefined' ? isInitialLoad : false;

	dom.form.each(function(i, el, a) {

		$.ajax({
			url: '/index.cfm?action=product.detailForm',
			method: 'POST',
			data: dom.form.eq(i).serialize() + '&isInitialLoad=' + isInitialLoad
		}).done(onLoadProductFormDone);

	});

};

var onLoadProductFormDone = function(response) {

	var $cachedForm = dom.form;

	if (response.status) {

		dom.form.filter('[data-item-id=' + response.itemId + ']').html(response.markup);

		setupDom();

		// addEventHandlers();

	}

};

var init = function() {

	console.log('product detail-form init called');

	setupDom();

	addEventHandlers();

	loadProductForm(true);

};

module.exports = {
	init: init
};
