'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var pbizServer = (typeof window !== "undefined" ? window.pbizServer : typeof global !== "undefined" ? global.pbizServer : null);

var appDom = require('./app-dom');

var dom;

var setupDom = function() {

	dom = {};
	dom.loginPage = $('.login-page');
	dom.submitBtn = dom.loginPage.find('.login-page__submit-btn');

};

var addEventHandlers = function() {

	dom.submitBtn.on('click', onSubmitBtnClicked);
	appDom.document.on('ajax_form_submission_failed', onAjaxFormSubmissionFailed);

};

var onSubmitBtnClicked = function() {

	dom.submitBtn.text(pbizServer.content['Generic.Loading']).parents('form').submit();

};

var onAjaxFormSubmissionFailed = function() {

	dom.submitBtn.text(pbizServer.content['Generic.Enter']);
	
};

var init = function() {

	setupDom();

	addEventHandlers();

};

module.exports = {
	init: init
};
