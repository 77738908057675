'use strict';

var Modernizr = (typeof window !== "undefined" ? window.Modernizr : typeof global !== "undefined" ? global.Modernizr : null);

var dom = require('./dom');

var instanceMap = {};

var create = function(formId, notifyContainer, notifyScrollContainer) {

	return instanceMap[formId] = new Settings(formId, notifyContainer, notifyScrollContainer);

};

var get = function(formId) {

	return instanceMap[formId];

};

var Settings = function(formId, notifyContainer, notifyScrollContainer) {

	this.formId = formId;
	this.domInstance = dom.get(formId);
	this.guid = this.domInstance.form.attr('data-form-guid');
	this.formTimeStamp = this.domInstance.form.attr('data-form-timestamp');
	this.formDataVersionKey = this.domInstance.form.attr('data-data-version-key');
	this.formData = this.domInstance.form.attr('data-form-data');
	this.classes = '';
	this.classes += this.useNativeDate ? ' fb-use-native-date' : '';
	this.classes += this.useNativeTime ? ' fb-use-native-time' : '';
	this.classes += this.useCustomSelect ? ' fb-use-custom-select' : '';
	this.classes += this.useJqueryUIDatepicker ? ' fb-use-jquery-ui-datepicker' : '';
	this.guidQuery = '?form_guid=' + this.guid;
	this.statesUrl = this.path + 'get_states.cfm' + this.guidQuery;
	this.submitUrl = this.path + 'submit_action.cfm' + this.guidQuery;
	this.shippingMethodsUrl = this.path + 'includes/payment_shipping_methods.cfm' + this.guidQuery;
	this.paymentDetailsUrl = this.path + 'includes/payment_details.cfm' + this.guidQuery;
	this.paymentCountiesUrl = this.path + 'includes/payment_county_options.cfm' + this.guidQuery;
	this.notifyContainer = notifyContainer;
	this.notifyScrollContainer = notifyScrollContainer;
	this.areLabelsOutside = this.domInstance.form.hasClass('fb-form--outside-label-style');

	this.domInstance.form
		.addClass(this.classes)
		.on('fbWidget-destroy', this.destroy.bind(this));

};

Settings.prototype.path = '/forms/widget/';
Settings.prototype.useNativeDate = Modernizr.touch && Modernizr.inputtypes.date;
Settings.prototype.useNativeTime = Modernizr.touch && Modernizr.inputtypes.time;
// Use custom JS select boxes if we don't have native time support
// on a touch device. This is necessary to get a consistent and
// properly layed out UI.
// Settings.prototype.useCustomSelect = !Settings.prototype.useNativeTime;
// Version of 2 form widget no longer supports custom selects, although the js code is being left as is for simplicity.
Settings.prototype.useCustomSelect = false;
// Use custom JS date picker if we don't have native date support
// on a touch device.
Settings.prototype.useJqueryUIDatepicker = !Settings.prototype.useNativeDate;

Settings.prototype.destroy = function() {

	delete instanceMap[this.formId];

};

module.exports = {
	create: create,
	get: get
};
