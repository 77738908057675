'use strict';

var dom = require('./dom');

var instanceMap = {};

var create = function(formId) {

	return instanceMap[formId] = new Fullname(formId);

};

var get = function(formId) {

	return instanceMap[formId];

};

var Fullname = function(formId) {

	this.formId = formId;
	this.domInstance = dom.get(formId);

	this.domInstance.form.on('fbWidget-destroy', this.destroy.bind(this));

};

Fullname.prototype.destroy = function() {

	delete instanceMap[this.formId];

};

Fullname.prototype.isFullnameType = function($col) {

	return $col.hasClass('fb-fullname-type');

};

Fullname.prototype.getVal = function($col) {

	var $els = this.domInstance.getFieldInputs($col);
	var vals = [];

	$els.each(function(i) {

		var $el = $els.eq(i);

		vals.push({
			name: this.domInstance.getNestedFieldTitleText($el),
			value: $el.val()
		});

	}.bind(this));

	return {
		name: 'Full Name',
		value: vals
	};

};

module.exports = {
	create: create,
	get: get
};
