'use strict';

var StateDetection = require('state-detection-2.0.0');

var appDom = require('../app-dom');

var directionMap = {
	'small': 0,
	'medium': 1,
	'large': 2
};
var state;
var imgStyles = new Image().style;
var supports = {
	touch: typeof appDom.app.attr('data-touch') !== 'undefined',
	objectFit: 'object-fit' in imgStyles,
	objectPosition: 'object-position' in imgStyles
};

var getState = function() {

	return state.getState();

};

var wasUpsized = function(oldState, newState) {

	return directionMap[newState] > directionMap[oldState];

};

var wasDownsized = function(oldState, newState) {

	return directionMap[newState] < directionMap[oldState];

};

var init = function() {

	state = new StateDetection({
		target: 'html'
	});

};

module.exports = {
	init: init,
	getState: getState,
	wasUpsized: wasUpsized,
	wasDownsized: wasDownsized,
	supports: supports
};
