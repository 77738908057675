'use strict';

/*

Usage

Add data-obj-fit to target, if needed, the value should be JSON

Required JSON props
	- width (unless iFrame)
	- height (unless iFrame)

Optional JSON props
	- targetContainer
		- selector string, defaults to target parent
	- containerBoxSize
		- defaults to 'content', can also be 'padding'
	- effect
		- defaults to 'contain', can also be 'cover' or 'scaleDown'
			- scaleDown will contain, but not stretch

*/

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var ScrollWatch = require('scrollwatch-2.0.1');

var domCache = require('dom-cache');
var imageProportion = require('image-proportion');
var lodashThrottle = require('lodash/function/throttle');

var instanceCount = 0;

var create = function(opts) {

	var dom;
	var options;
	var instanceId;
	var scrollWatchInstance;

	// Apply a css cover or contain effect to each object. The objects's container dimensions are controlled outside of this module. This module simply fills or fits the object based on it's container's size.
	var adjustObj = function($obj, objData) {

		var data = $.extend({
			targetContainer: '',
			containerBoxSize: 'content',
			effect: 'contain'
		}, objData);
		var $container = data.targetContainer ? $obj.closest(data.targetContainer) : $obj.parent();
		// Get the container size
		var containerWidth = data.containerBoxSize === 'padding' ?  $container.width() + parseFloat($container.css('padding-left')) + parseFloat($container.css('padding-right')) : $container.width();
		var containerHeight = data.containerBoxSize === 'padding' ? $container.height() + parseFloat($container.css('padding-top')) + parseFloat($container.css('padding-bottom')) : $container.height();
		var objWidth = data.width;
		var objHeight = data.height;
		var scaledProportion;

		if ($obj.is('iframe')) {

			// Get the width and height directly from the respective attributes
			objWidth = parseInt($obj.attr('width'), 10);
			objHeight = parseInt($obj.attr('height'), 10);

		}

		scaledProportion = imageProportion[data.effect](objWidth, objHeight, containerWidth, containerHeight);

		// Cover or contain the object within the container, it is assumed the object is positioning itself in it's own css
		$obj
			.css({
				width: scaledProportion.width,
				height: scaledProportion.height
			})
			.attr('data-obj-fit-processed', '');

	};

	// Used when not in watchMode on init and window resize
	var adjustAll = function() {

		// Adjust all objects
		dom.objs.each(function(i) {

			var $obj = dom.objs.eq(i);

			adjustObj($obj, $obj.data(options.dataAttributeName));

		});

	};

	// Only used with watchMode on window resize
	var adjustAllProcessed = function() {

		// Adjust any obj that has previously been adjusted
		dom.objs.filter('[data-obj-fit-processed]').each(function() {

			var $obj = $(this);

			adjustObj($obj, $obj.data(options.dataAttributeName));

		});

	};

	// Used when not in watchMode and new objects have been added, ie refresh has been called
	var adjustNewObjs = function() {

		// Adjust new objects to the dom that have not been processed yet
		dom.objs.filter(':not([data-obj-fit-processed])').each(function() {

			var $obj = $(this);

			adjustObj($obj, $obj.data(options.dataAttributeName));

		});

	};

	var adjust = function() {

		if (options.watchMode) {

			adjustAllProcessed();

		} else {

			adjustAll();

		}

	};

	var addEventHandlers = function() {

		domCache.window.off('.obj-fit-' + instanceId);
		domCache.window.on('resize.obj-fit-' + instanceId, lodashThrottle(adjust, options.resizeThrottle));
		domCache.window.on('load.obj-fit-'+instanceId, adjust);

	};

	var setupDom = function() {

		dom = {objs: $(options.target)};

	};

	var setupOptions = function() {

		options = {
			dataAttributeName: 'obj-fit',
			target: '[data-obj-fit]',
			watchMode: false,
			watchOffsetXLeft: 500,
			watchOffsetXRight: 500,
			watchOffsetYTop: 500,
			watchOffsetYBottom: 500,
			scrollThrottle: 250,
			resizeThrottle: 250
		};

		$.extend(options, opts || {});

	};

	var destroy = function() {

		if (scrollWatchInstance) {

			scrollWatchInstance.destroy();
			scrollWatchInstance = null;

		}

		domCache.window.off('.obj-fit-' + instanceId);
		dom = null;
		options = null;
		instanceId = null;

	};

	var refresh = function() {

		if (options.watchMode) {

			scrollWatchInstance.refresh();

		} else {

			setupDom();
			adjustNewObjs();

		}

	};

	var init = function() {

		instanceId = instanceCount++;
		setupOptions();
		setupDom();
		addEventHandlers();

		if (options.watchMode) {

			// Setup a watch to apply cover or contain effect when targets are close to being in the viewport
			scrollWatchInstance = new ScrollWatch({
				watch: options.target,
				// Use obj-fit specific classes to avoid collisions with any other watchers from other modules
				ignoreClass: 'obj-fit-scroll-watch-ignore',
				inViewClass: 'obj-fit-scroll-watch-in-view',
				watchOffsetXLeft: options.watchOffsetXLeft,
				watchOffsetXRight: options.watchOffsetXRight,
				watchOffsetYTop: options.watchOffsetYTop,
				watchOffsetYBottom: options.watchOffsetYBottom,
				scrollThrottle: options.scrollThrottle,
				resizeThrottle: options.resizeThrottle,
				onElementInView: function(data) {

					var $obj = $(data.el);

					adjustObj($obj, $obj.data(options.dataAttributeName));

				}
			});

		} else {

			adjustAll();

		}

	};

	init();

	return {
		destroy: destroy,
		refresh: refresh
	};

};

module.exports = {
	create: create
};
