'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var domCache = require('dom-cache');

var isInited = false;

var onFocus = function() {

	$(this).attr('placeholder', '');

};

var onBlur = function() {

	var $this = $(this);

	$this.attr('placeholder', $this.attr('data-placeholder-toggle'));

};

var init = function() {

	if (isInited) {

		return;

	}

	isInited = true;

	domCache.document
		.on('focus.placeholder-toggle', '[data-placeholder-toggle]', onFocus)
		.on('blur.placeholder-toggle', '[data-placeholder-toggle]', onBlur);

};

var destroy = function() {

	domCache.document.off('.placeholder-toggle');

};

module.exports = {
	init: init,
	destroy: destroy
};
