'use strict';

var dom = require('./dom');

var instanceMap = {};

var create = function(formId) {

	return instanceMap[formId] = new Address(formId);

};

var get = function(formId) {

	return instanceMap[formId];

};

var Address = function(formId) {

	this.formId = formId;
	this.domInstance = dom.get(formId);

	this.domInstance.form.on('fbWidget-destroy', this.destroy.bind(this));

};

Address.prototype.destroy = function() {

	delete instanceMap[this.formId];

};

Address.prototype.isAddressType = function($col) {

	return $col.hasClass('fb-address-type');

};

Address.prototype.getVal = function($col) {

	var $els = this.domInstance.getFieldInputs($col);
	var name = this.domInstance.getFieldTitleText($col);
	var vals = [];

	$els.each(function(i) {

		var $el = $els.eq(i);

		vals.push({
			name: this.domInstance.getNestedFieldTitleText($el),
			value: $el.val()
		});

	}.bind(this));

	return {
		name: name,
		value: vals
	};

};

module.exports = {
	create: create,
	get: get
};
