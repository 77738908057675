'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var notify = require('notify');
var pbizServer = (typeof window !== "undefined" ? window.pbizServer : typeof global !== "undefined" ? global.pbizServer : null);
var recaptcha = require('recaptcha');

var appDom = require('../app-dom');

var dom;
var originalBtnText;
var recaptchaWidgetId;

var setupDom = function() {

	dom = {};
	dom.blogDetail = $('.blog-detail');
	dom.commentForm = dom.blogDetail.find('.blog-detail__comment-form');
	dom.submitCommentBtn = dom.blogDetail.find('.blog-detail__comment-form-btn');
	dom.commentDisplay = dom.blogDetail.find('#commentDisplay');

};

var addEventHandlers = function() {

	dom.submitCommentBtn.on('click', onSubmitCommentBtnClicked);

};

var onSubmitCommentBtnClicked = function(e) {

	e.preventDefault();

	// If this form supports recaptcha and we don't have a token yet, execute the challenge logic
	if (typeof recaptchaWidgetId !== 'undefined' && !window.grecaptcha.getResponse(recaptchaWidgetId)) {

		// Execute the recaptcha challenge, which will either immediately pass or present the user with a challenge to complete
		window.grecaptcha.execute(recaptchaWidgetId)
			.catch(function() {

				// An error in here most likely means the site key is incorrect

				notify.show({
					msg: pbizServer.content['Generic.UnexpectedError'] + ' (EC7.6)',
					target: dom.submitCommentBtn,
					closeOnClick: true,
					container: dom.commentForm
				});

			});

	} else {

		submitCommentForm();

	}

};


var submitCommentForm = function() {

	originalBtnText = dom.submitCommentBtn.text();

	dom.submitCommentBtn.text(pbizServer.content['Generic.Loading']);

	$.ajax({
		url: dom.commentForm.attr('action'),
		method: 'post',
		data: dom.commentForm.serialize(),
	}).done(onSubmitCommentBtnClickedDone);

};

var onSubmitCommentBtnClickedDone = function(response) {

	console.log('click btn done');
	console.log(response);

	var $target = dom.submitCommentBtn;

	if (response.target) {

		$target = $('[name="' + response.target + '"]');

	}

	if (response.status.code) {

		if (response.status.detail === 'COMMENT_MODERATION_REQUIRED') {

			notify.show({
				msg: response.status.msg,
				type: 'info',
				target: dom.submitCommentBtn,
				closeOnClick: true,
				container: dom.commentForm
			});

		} else {

			dom.commentDisplay.html(response.markup);

		}

		dom.commentForm[0].reset();
		resetRecaptcha();

	} else {

		if (response.status.detail === 'RECAPTCHA_VERIFICATION_ERROR') {

			resetRecaptcha();

		}

		$target.focus();

		notify.show({
			msg: response.status.msg,
			target: $target,
			closeOnClick: true,
			container: dom.commentForm
		});

	}

	dom.submitCommentBtn.text(originalBtnText);

};

var resetRecaptcha = function() {

	if (typeof recaptchaWidgetId !== 'undefined') {

		window.grecaptcha.reset(recaptchaWidgetId);

	}

};

var setupRecaptcha = function() {

	var $grecaptcha = dom.commentForm.find('.g-recaptcha');

	if ($grecaptcha.length) {

		// This form should use recaptcha, so set it up
		recaptcha.init(pbizServer.languageTag)
			.then(function() {

				recaptchaWidgetId = window.grecaptcha.render($grecaptcha[0], {
					callback: submitCommentForm
				}, true);

				console.log('****** RECAPTCHA WIDGET ID ******');
				console.log(recaptchaWidgetId);

			})
			.catch(console.warn);

	}

};

var init = function() {

	// console.log('comment form is runnning!')
	// console.log(appDom.document.find('.blog-detail__comment-form'))

	if (appDom.document.find('.blog-detail__comment-form')) {

		setupDom();

		addEventHandlers();

		setupRecaptcha();

	}

};

module.exports = {
	init: init
};
