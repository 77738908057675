'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var dom = require('./dom');
var settings = require('./settings');

var instanceMap = {};

var create = function(formId) {

	return instanceMap[formId] = new Selectmenu(formId);

};

var get = function(formId) {

	return instanceMap[formId];

};

var Selectmenu = function(formId) {

	var $customSelectWrappers;
	var thisInstance = this;

	this.formId = formId;
	this.domInstance = dom.get(formId);
	this.settingsInstance = settings.get(formId);

	if (this.settingsInstance.useCustomSelect) {

		this.domInstance.form
			.on({
				'fbWidget-address-states-populated': this.onAddressStatesPopulated.bind(this),
				'fbWidget-familyfriends-field-added': this.onFamilyFriendsFieldAdded.bind(this),
				'fbWidget-rating-radio-clicked': this.onRatingRadioClicked.bind(this),
				'fbWidget-dropdown-reset': this.onDropdownReset.bind(this)
			})
			.on('change', 'select', this.onSelectChangeWithCustom.bind(this));

	} else {

		// Remove elements associated with custom selects, leaving only
		// a native select element.

		$customSelectWrappers = this.domInstance.form.find('.fb-selectmenu');

		$customSelectWrappers.each(function(i, el) {

			var $wrapper = $(el);
			var $select = $wrapper.find('select').detach();

			$wrapper.replaceWith($select);

		});

		// In order to change the color of the title when it's the first option of a select (happens when label style is "inside" or "with-icon"), we need to add a special class to the select since changing the color directly on an <option> via css does not work cross browser
		if (!this.settingsInstance.areLabelsOutside) {

			this.domInstance.form.on('change', 'select', this.onSelectChange.bind(this));

			this.domInstance.form.find('select').each(function() {

				thisInstance.toggleEmptySelectionClass($(this));

			});

		}

	}

	this.domInstance.form.on({
		'fbWidget-destroy': this.destroy.bind(this)
	});

};

Selectmenu.prototype.destroy = function() {

	delete instanceMap[this.formId];

};

// Set the button text to match the selected option.
Selectmenu.prototype.sync = function($target) {

	if (!this.settingsInstance.useCustomSelect) {

		return;

	}

	$target.each(function(i, el) {

		var $el = $(el);
		// Get the text instead of the value b/c codes are used as values for
		// address country and state and we want to display the full names.
		var val = $el.find(':selected').text();

		$el
			.closest('.fb-selectmenu')
				.find('.fb-selectmenu-text')
					.text(val);

	});

};

Selectmenu.prototype.toggleEmptySelectionClass = function($select) {

	var $selectedOption = $select.find(':selected');
	var text = $selectedOption.text();

	if ($selectedOption.val() === '' && text !== 'Select' && text !== '--') {

		$select.addClass('fb-field-input--select-with-title-selected');

	} else {

		$select.removeClass('fb-field-input--select-with-title-selected');

	}

};

Selectmenu.prototype.onSelectChange = function(e) {

	var $select = $(e.target).closest('select');

	this.toggleEmptySelectionClass($select);

};

Selectmenu.prototype.onSelectChangeWithCustom = function(e) {

	var $select = $(e.target).closest('select');

	this.sync($select);

};

Selectmenu.prototype.onAddressStatesPopulated = function(e, data) {

	this.sync(data.$select);

};

Selectmenu.prototype.onFamilyFriendsFieldAdded = function(e, data) {

	this.sync(data.$select);

};

Selectmenu.prototype.onRatingRadioClicked = function(e, data) {

	this.sync(data.$select);

};

Selectmenu.prototype.onDropdownReset = function(e, data) {

	this.sync(data.$select);

};

module.exports = {
	create: create,
	get: get
};
