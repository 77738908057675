'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var notify = require('notify');
var pbizServer = (typeof window !== "undefined" ? window.pbizServer : typeof global !== "undefined" ? global.pbizServer : null);
var triggerCustomEvent = require('trigger-custom-event');

var appDom = require('../../app-dom');
var utils = require('../../utils/main');

var dom;

var setupDom = function() {

	dom = {};
	dom.giftCardBlock = $('.gift-card-block');
	dom.addToCartForm = dom.giftCardBlock.find('.gift-card-block__add-to-cart-form');
	dom.checkBalanceForm = dom.giftCardBlock.find('.gift-card-block__check-balance-form');
	dom.giftCardAmount = dom.giftCardBlock.find('[name="giftCardAmount"]');
	dom.giftCardCode = dom.giftCardBlock.find('[name="giftCardCode"]');
	dom.giftCardEmail = dom.giftCardBlock.find('[name="recipientEmail"]');
	dom.giftCardMessage = dom.giftCardBlock.find('[name="recipientMessage"]');
	dom.addToCartBtn = dom.giftCardBlock.find('.js-add-gift-card-to-cart');
	dom.checkBalanceBtn = dom.giftCardBlock.find('.js-check-gift-card-balance');

};

var addEventHandlers = function() {

	dom.giftCardBlock.on('click', '.js-check-gift-card-balance', onCheckBalanceBtnClicked);
	dom.giftCardBlock.on('click', '.js-add-gift-card-to-cart', onAddToCartBtnClicked);
	dom.addToCartForm.on('submit', onAddToCartFormSubmitted);
	dom.checkBalanceForm.on('submit', onCheckBalanceFormSubmitted);

};

var onCheckBalanceBtnClicked = function() {

	dom.checkBalanceForm.submit();

};


var onAddToCartBtnClicked = function() {

	dom.addToCartForm.submit();

};

var onAddToCartFormSubmitted = function(e) {

	e.preventDefault();

	dom.addToCartBtn.text(pbizServer.content['Generic.Loading']);

	$.ajax({
		url: utils.getUrl('cart.add'),
		method: 'POST',
		data: {
			amount: dom.giftCardAmount.val(),
			recipientEmail: dom.giftCardEmail.val(),
			recipientMessage: dom.giftCardMessage.val(),
			itemType: 'Gift Card',
			sourceUrl: dom.addToCartBtn.attr('data-item-url')
		}
	}).done(onAddToCartFormSubmittedDone);

};

var onAddToCartFormSubmittedDone = function(response) {

	var giftCardType = dom.giftCardAmount.attr('data-gift-card-type');

	dom.addToCartBtn.text(pbizServer.content['Cart.AddToCart']);

	if (response.status.code) {

		triggerCustomEvent('item_added_to_cart');

		notify.hide();

		if (giftCardType === 'increment') {

			dom.giftCardAmount.val(0);

		} else { // 'range' type

			dom.giftCardAmount.val('');

		}

		dom.giftCardEmail.val('');
		dom.giftCardMessage.val('');

	} else {

		notify.show({
			target: dom.giftCardBlock.find('[name="' + response.target + '"]'),
			msg: response.status.msg,
			closeOnClick: true
		});

	}

};

var onCheckBalanceFormSubmitted = function(e) {

	e.preventDefault();

	dom.checkBalanceBtn.text(pbizServer.content['Generic.Loading']);

	$.ajax({
		url: utils.getUrl('giftcard.checkBalance'),
		method: 'POST',
		data: {
			code: dom.giftCardCode.val()
		}
	}).done(onCheckBalanceFormSubmittedDone);

};

var onCheckBalanceFormSubmittedDone = function(response) {

	dom.checkBalanceBtn.text(pbizServer.content['GiftCard.CheckCard']);

	if (response.status.code) {

		notify.show({
			target: dom.giftCardCode,
			msg: response.status.msg,
			type: 'info',
			closeOnClick: true
		});

	} else {

		notify.show({
			target: dom.giftCardCode,
			msg: response.status.msg,
			closeOnClick: true
		});

	}

};

var init = function() {

	if (appDom.document.find('.gift-card-block')) {

		setupDom();

		addEventHandlers();

	}

};

module.exports = {
	init: init
};
