'use strict';

var dom = require('./dom');

var instanceMap = {};

var create = function(formId) {

	return instanceMap[formId] = new MutationWatch(formId);

};

var MutationWatch = function(formId) {

	this.formId = formId;
	this.domInstance = dom.get(formId);

	this.domInstance.form
		.on('fbWidget-destroy', this.destroy.bind(this))
		.on('fbWidget-submission-shown fbWidget-linked-field-hidden fbWidget-linked-field-shown fbWidget-payment-shipping-address-option-changed fbWidget-payment-method-changed fbWidget-rating-layout-adjusted fbWidget-familyfriends-field-added fbWidget-familyfriends-field-removed', this.triggerMutationEvent.bind(this));

};

MutationWatch.prototype.destroy = function() {

	delete instanceMap[this.formId];

};

MutationWatch.prototype.triggerMutationEvent = function() {

	this.domInstance.triggerFormEvent('fbWidget-mutation');

};

module.exports = {
	create: create
};
