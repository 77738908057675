'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var lodashThrottle = require('lodash/function/throttle');
var lodashDebounce = require('lodash/function/debounce');
var Hammer = require('Hammer');
var ScrollWatch = require('scrollwatch-2.0.1');

var triggerCustomEvent = require('trigger-custom-event');
var loadImage = require('load-image');
var objFit = require('obj-fit');
var focalPoint = require('focal-point-3.0.0');

var modal = require('../modal');
var utils = require('../utils/main');
var appDom = require('../app-dom');

var instanceMap = {};

var create = function($gallery, galleryId, hasMoreItems) {

	return instanceMap[galleryId] = new Slideshow($gallery, galleryId, hasMoreItems);

};

var get = function(galleryId) {

	return instanceMap[galleryId];

};

var Slideshow = function($gallery, galleryId, hasMoreItems) {

	this.$gallery = $gallery;
	this.galleryId = galleryId;
	this.$slideshow = this.$gallery.find('.gallery__slideshow');
	this.$itemVisibilityStyle = this.$slideshow.find('.gallery__item-visibility-style-element');
	this.$itemOrderStyle = this.$slideshow.find('.gallery__item-order-style-element');
	this.$left = this.$slideshow.find('.gallery__left-arrow');
	this.$right = this.$slideshow.find('.gallery__right-arrow');
	this.$itemContainer = this.$slideshow.find('.gallery__items');
	this.$items = this.$itemContainer.find('.gallery__item');
	this.$activeItem = this.$items.filter('.gallery__item--is-active');
	this.$lastActiveItem = this.$activeItem;
	this.hasMouseMovement = false;
	this.isPlaying = false;
	// If we have more items, block wrapping until we get the rest after the first interaction
	this.isWrapEnabled = hasMoreItems ? false : true;
	// Is item transition running
	this.isTransitioning = false;
	this.slideOffset = 0;
	this.$queue = [];
	this.isFullScreenMode = this.$gallery.hasClass('gallery--full-screen-mode');
	this.isClassicStyle = this.$gallery.hasClass('gallery--classic-style');
	this.isCarouselStyle = !this.isClassicStyle;
	this.doesCarouselLoop = this.$gallery.hasClass('gallery--carousel-loop');

	appDom.document.on({
		'form_mutation': this.onFormMutation.bind(this),
		'product_option_changed': this.onProductOptionChanged.bind(this),
		'service_option_changed': this.onServiceOptionChanged.bind(this)
	});

	this.$gallery.on({
		'gallery_thumb_changed': this.onGalleryThumbChanged.bind(this),
		'gallery_items_fetched': this.onGalleryItemsFetched.bind(this)
	});

	this.$itemContainer.on('click', '.js-toggle-gallery-caption-state', this.toggleCaptionState.bind(this));

	if (utils.supports.touch) {

		// Create a Hammer instance that listens for and recognizes horizontal swipes
		this.hammerInstance = new Hammer.Manager(this.$slideshow[0], {
			recognizers: [
				[Hammer.Swipe, {direction: Hammer.DIRECTION_HORIZONTAL}]
			]
		});

		this.hammerInstance
			.on('swipeleft', this.userNext.bind(this))
			.on('swiperight', this.userPrevious.bind(this));

	} else {

		// Give each instance it's own debounced version of removeMouseMovementClass in order to keep the object reference (this) straight
		this.removeMouseMovementClass = lodashDebounce(this.removeMouseMovementClass, 1000);

		this.$gallery.find('.js-track-gallery-mouse-movement').on('mousemove', lodashThrottle(this.trackMouseMovement.bind(this), 100));

		this.$slideshow.find('.js-show-previous-gallery-item').on('click', this.userPrevious.bind(this));

		this.$slideshow.find('.js-show-next-gallery-item').on('click', this.userNext.bind(this));

	}

	// Full screen logic needs to be run before auto play, object fit, and focal point logic because the gallery has not yet been injected into the DOM
	if (this.isFullScreenMode) {

		// Move the items that are more than 3 after the active item into the queue
		this.$queue = this.$items.slice(this.getItemSequence(this.$activeItem) + 3);

		// Linearly render the items up to 3 more than the active item
		this.$items = this.$items.slice(0, this.getItemSequence(this.$activeItem) + 3);
		this.$itemContainer.html(this.$items);

		modal.setContent(this.$gallery);

		// this.preloadImage(this.$activeItem);
		this.injectItems();
		this.preloadImages();

		appDom.document.on('keydown.gallery-slideshow', this.onKeyDown.bind(this));

	}

	if (this.$gallery.hasClass('gallery--auto-play')) {

		if (this.$items.length > 1) {

			this.autoPlayWatcher = new ScrollWatch({
				watch: '#' + this.galleryId,
				// watchOnce: false,
				ignoreClass: 'gallery-scroll-watch-ignore',
				inViewClass: 'gallery-scroll-watch-in-view',
				onElementInView: this.startAutoPlay.bind(this)/*,
				onElementOutOfView: this.stopAutoPlay.bind(this)*/
			});

		}

	}

	// Temporarily remove browser support check because of bug with mobile safari related to scale-down. The only way to fix the issue is to use the JS solution across the board.
	if (/*!utils.supports.objectFit && !utils.supports.objectPosition &&*/ this.$gallery.find('[data-gallery-obj-fit]').length) {

		this.objFit = objFit.create({
			dataAttributeName: 'gallery-obj-fit',
			target: '#' + this.galleryId + ' [data-gallery-obj-fit]'
		});

	}

	if (this.$gallery.find('[data-gallery-focal-point]').length) {

		this.focalPoint = focalPoint({
			dataAttributeName: 'gallery-focal-point',
			target: '#' + this.galleryId + ' [data-gallery-focal-point]',
			resizeThrottle: 0
		});

	}

	if (this.isCarouselStyle) {

		this.orderStack = [];
		this.cacheItemContainerHeight();
		this.setImgWidths(this.$items);
		this.$itemContainer.addClass('gallery__items--initial-space-allocated');
		this.$itemContainer.on('transitionend', function(e) {

			// Make sure this event is from the sliding transition and not the caption fading transition
			if (this.$itemContainer.is(e.target)) {

				this.onSlideTransitionEnd();

			}

		}.bind(this));
		appDom.window.on('resize', lodashDebounce(this.onWindowResize.bind(this), 200));

	}

};

Slideshow.prototype.setImgWidths = function($items) {

	var instance = this;

	$items.each(function() {

		var $item = $(this);
		var $img = $item.find('.gallery__img');
		var imgWidth;
		var imgHeight;

		// Note: leaving inline widths in dom (and resetting on window resize) because firefox does not correctly size the flex items to match the dimensions of the child image
		// Only set inline width if the image isn't loaded. If it is loaded, the browser will know the aspect ratio and automatically scale the width for us.
		// if (!$.data($img[0], 'isLoaded')) {

			// Use .data() so values will be cached after the first read
			// imgWidth = $img.data('gallery-img-width');
			// imgHeight = $img.data('gallery-img-height');
			// $img.css('width', instance.itemContainerHeight * imgWidth / imgHeight);

		// }

		imgWidth = $img.data('gallery-img-width');
		imgHeight = $img.data('gallery-img-height');
		$item.css('width', instance.itemContainerHeight * imgWidth / imgHeight);

	});

};

Slideshow.prototype.onWindowResize = function() {

	this.cacheItemContainerHeight();
	this.setImgWidths(this.$items);
	this.slide();

};

Slideshow.prototype.cacheItemContainerHeight = function() {

	this.itemContainerHeight = this.$itemContainer.height();

};

Slideshow.prototype.destroy = function() {

	appDom.document.off('.gallery-slideshow');

	if (this.autoPlayWatcher) {

		this.autoPlayWatcher.destroy();

	}

	if (this.objFit) {

		this.objFit.destroy();

	}

	if (this.focalPoint) {

		this.focalPoint.destroy();

	}

	instanceMap[this.galleryId] = null;

};

Slideshow.prototype.onKeyDown = function(e) {

	if (e.keyCode === 37) {

		// Left arrow
		this.userPrevious();

	} else if (e.keyCode === 39) {

		// Right arrow
		this.userNext();


	} else if (e.keyCode === 32) {

		// Spacebar
		this.toggleCaptionState(e);

	}

};

Slideshow.prototype.startAutoPlay = function() {

	this.isPlaying = true;
	this.autoPlayTimer = window.setInterval(this.next.bind(this), 5000);

};

Slideshow.prototype.stopAutoPlay = function() {

	if (this.isPlaying) {

		this.isPlaying = false;
		window.clearInterval(this.autoPlayTimer);
		this.autoPlayWatcher.destroy();
		this.autoPlayWatcher = null;

	}

};

Slideshow.prototype.toggleCaptionState = function(e) {

	this.$gallery.toggleClass('gallery--has-collapsed-captions');
	e.stopPropagation();
	e.preventDefault();

};

// Preload 6 to the left and right of the active item, this gives us enough to have any image that can be targeted from a thumbnail
Slideshow.prototype.lazyLoadRange = [0, -1, -2, -3, -4, -5, -6, 1, 2, 3, 4, 5, 6];

Slideshow.prototype.getItemSequence = function($item) {

	return $item.data('galleryItemSequence');

};

Slideshow.prototype.preloadImage = function($item) {

	var $img = $item.find('.gallery__img');
	var imgSrc;

	// Make sure this item has an image
	if ($img.length) {

		// Fixed parallax used a <div>, now that we have real parallax, we'll always have an <img>
		// if ($img.is('img')) {

		if (!$.data($img[0], 'isLoaded')) {

			imgSrc = $img.attr('data-gallery-img-src');

			loadImage(imgSrc, function() {

				$img
					.attr('src', imgSrc)
					.addClass('gallery__img--loaded');

				$.data($img[0], 'isLoaded', true);

			});

		}

		// } else {

		// 	if (!$.data($img[0], 'isLoaded')) {

		// 		imgSrc = $img.attr('data-gallery-img-src');

		// 		loadImage(imgSrc, function() {

		// 			$img
		// 				.css('background-image', 'url(' + imgSrc + ')')
		// 				.removeClass('u-img-loading');
		// 			$.data($img[0], 'isLoaded', true);

		// 		});

		// 	}

		// }

	}

};

// Our initial batch of images were preloaded via the lazy-image module. Once the user starts interacting with the gallery, the gallery will take over responsibility for preloading images.
Slideshow.prototype.preloadImages = function() {

	var instance = this;
	var activeDomIndex = this.$items.index(this.$activeItem);
	var numItems = this.$items.length;
	var css = '';

	this.lazyLoadRange.forEach(function(rangeIndex) {

		var targetIndex = activeDomIndex - rangeIndex;
		var cssTargetIndex;

		if (targetIndex >= numItems || targetIndex < 0 - numItems) {

			// Make sure we're not looking too far to the right or left
			targetIndex = targetIndex % numItems;

		}

		cssTargetIndex = targetIndex >= 0 ? targetIndex + 1 : numItems + targetIndex + 1;
		css += (css ? ',' : '') + '#' + instance.galleryId + ' .gallery__item:nth-child(' + cssTargetIndex + ') .gallery__img';

		instance.preloadImage(instance.$items.eq(targetIndex));

	});

	this.$itemVisibilityStyle.html('#' + this.galleryId + ' .gallery__img {display: none;} ' + css + '{display: block;}');

};

Slideshow.prototype.injectItems = function() {

	var $itemsToInject;
	var lowSequence;
	var highSequence;
	var $sequenceNeighbors;
	var $sequenceNeighbor;
	var i;

	for (i = 0; i < 2; i++) {

		if (this.$queue.length) {

			if (i === 0) {

				// Get the first 3 from the queue
				$itemsToInject = this.$queue.slice(0, 3);

				// Remove from the queue
				this.$queue = this.$queue.slice(3);

			} else {

				// Get the last 3 from the queue
				$itemsToInject = this.$queue.slice(-3);

				// Remove from the queue
				this.$queue = this.$queue.slice(0, -3);

			}

			if (this.isCarouselStyle) {

				this.setImgWidths($itemsToInject);

			}

			// Try to find a sequence in the DOM that is either one before the lowest sequence in our batch or one after the highest sequence in our batch. We're grouping the filter so we only have to search once.
			lowSequence = this.getItemSequence($itemsToInject.first());
			highSequence = this.getItemSequence($itemsToInject.last());
			$sequenceNeighbors = this.$items.filter('[data-gallery-item-sequence="' + (lowSequence - 1) + '"],[data-gallery-item-sequence="' + (highSequence + 1) + '"]');

			if ($sequenceNeighbors.length) {

				$sequenceNeighbor = $sequenceNeighbors.first();

				if (this.getItemSequence($sequenceNeighbor) === lowSequence - 1) {

					$sequenceNeighbor.after($itemsToInject);

				} else {

					$sequenceNeighbor.before($itemsToInject);

				}

			} else {

				// No target found, so just inject at the end
				this.$itemContainer.append($itemsToInject);

			}

			// Refresh items cache
			this.$items = this.$itemContainer.find('.gallery__item');

			if (this.isCarouselStyle) {

				this.slide(true);

			}

		}

	}

	if ($itemsToInject) {

		// We'll only have this.objFit if the browser doesn't natively support obj-fit
		if (this.objFit) {

			this.objFit.refresh();

		}

		// If we're in full screen mode, this.focalPoint won't exist the first time in
		if (this.focalPoint) {

			this.focalPoint.refresh();

		}

	}

};

Slideshow.prototype.triggerItemChanged = function() {

	triggerCustomEvent('gallery_item_changed', {
		target: this.$gallery,
		data: {
			$activeItem: this.$activeItem,
			activeSequence: this.getItemSequence(this.$activeItem)
		}
	});

};

Slideshow.prototype.removeMouseMovementClass = function() {

	this.hasMouseMovement = false;
	this.$gallery.removeClass('gallery--has-mouse-movement');

};

Slideshow.prototype.reorderCarousel = function() {

	var css = '';
	var order;
	var lastActiveItemSequence;
	var currentActiveItemSequence;
	var $itemToResetSlideTo;

	if (!this.isCarouselStyle || !this.doesCarouselLoop) {

		return;

	}

	if (this.lastSlideDirection === 'right') {

		$itemToResetSlideTo = this.$activeItem;
		lastActiveItemSequence = this.getItemSequence(this.$lastActiveItem);

		if (this.isFirstItem(this.$activeItem)) {

			// We just slid right to the first item, ie we have looped all the way around, so remove any ordering and start over
			this.orderStack = [];

		} else {

			if (this.orderStack[this.orderStack.length - 1] === lastActiveItemSequence) {

				// The item we just slid right from has been moved to display at the beinning of the list, so remove it from the stack, which will reset it to the end
				this.orderStack.pop();
				// Visually move/keep all items remaining in the stack to the beginning of the list
				order = -1;

			} else {

				// Add the item we just slid from to the stack so that it will get moved to the end of the list
				this.orderStack.push(lastActiveItemSequence);
				// Visually move all items in the stack to the end of the list
				order = 1;

			}

		}

	} else if (this.lastSlideDirection === 'left') {

		$itemToResetSlideTo = this.$lastActiveItem;
		currentActiveItemSequence = this.getItemSequence(this.$activeItem);

		if (this.isFirstItem(this.$activeItem)) {

			// The item we want to slide left to is the first item, so remove any ordering and start over
			this.orderStack = [];

		} else {

			if (this.orderStack[this.orderStack.length - 1] === currentActiveItemSequence) {

				// The item we want to slide left to is has been moved to display at the end of the list, so remove it from the stack, which will reset it to the beginning
				this.orderStack.pop();
				// Visually move/keep all items remaining in the stack to the end of the list
				order = 1;

			} else {

				// Add the item we want to slide left to to the stack so that it will get moved to the beginning of the list
				this.orderStack.push(currentActiveItemSequence);
				// Visually move all items in the stack to the beginning of the list
				order = -1;

			}

		}

	}

	this.orderStack.forEach(function(sequence) {

		css += (css ? ',' : '') + '#' + this.galleryId + ' [data-gallery-item-sequence="' + sequence + '"]';

	}, this);

	this.$itemOrderStyle.html(css + (css ? '{order:' + order + '}' : ''));
	this.slide(true, $itemToResetSlideTo);

};

Slideshow.prototype.onSlideTransitionEnd = function() {

	this.isTransitioning = false;

	// If the direction was left, we did the reordering before the slide animation happened
	if (this.lastSlideDirection === 'right') {

		this.reorderCarousel();

	}

};

Slideshow.prototype.onFadeTransitionEnd = function($item) {

	// Our old item has finished fading out, remove the class so our image element will get reset to display none, thus freeing up memory
	$item.removeClass('gallery__item--is-fading-out');

	this.isTransitioning = false;

};

Slideshow.prototype.slide = function(disableTransition, $item) {

	var oldSlideOffset = this.slideOffset;

	$item = $item || this.$activeItem;

	this.slideOffset = -$item.position().left;

	if (oldSlideOffset === this.slideOffset) {

		return;

	}

	if (!disableTransition) {

		this.isTransitioning = true;

	}

	this.$itemContainer
		.css({
			// The code commented below used to be the way we handled disabling the slide transition, however iOS/Safari 14 broke a lot of things related to transitions. The simplest solution to the problem was specifically updating the transition duration.
			// 'transition': disableTransition ? 'none' : '',
			'transition-duration': disableTransition ? '0s' : '.6s',
			'transform': 'translate3d(' + this.slideOffset + 'px,0,0)'
		});

};

Slideshow.prototype.setActiveItem = function($item) {

	this.$lastActiveItem = this.$activeItem;

	if (this.isClassicStyle) {

		this.isTransitioning = true;

		this.$activeItem
			.addClass('gallery__item--is-fading-out')
			.removeClass('gallery__item--is-active')
			.one('transitionend', this.onFadeTransitionEnd.bind(this, this.$activeItem));

		this.$activeItem = $item.addClass('gallery__item--is-active');

		if (this.focalPoint) {

			this.focalPoint.refreshParallax();

		}

	} else {

		this.$activeItem.removeClass('gallery__item--is-active');
		this.$activeItem = $item.addClass('gallery__item--is-active');

	}

	this.toggleArrows();

};

Slideshow.prototype.toggleArrows = function() {

	this.$left.toggleClass('gallery__arrow--is-disabled', this.isFirstItem(this.$activeItem) && !this.isWrapEnabled);

	this.$right.toggleClass('gallery__arrow--is-disabled', this.isLastItem(this.$activeItem) && !this.isWrapEnabled);

};

// If a product option has changed and the option has an image in the gallery, show that image
Slideshow.prototype.onProductOptionChanged = function(e, data) {

	var galleryBlockId = utils.dom.getClosestBlockId(this.$gallery);
	var optionBlockId = utils.dom.getClosestBlockId(data.$option);
	var $galleryImg;

	// Check if this option has an associated image, and that this option and this gallery are from the same block
	if (data.imgId && galleryBlockId === optionBlockId) {

		// Search the gallery items for the image associated with the option
		$galleryImg = this.$items.find('.gallery__img').filter('[data-gallery-img-id="' + data.imgId + '"]');

		if ($galleryImg.length) {

			this.stopAutoPlay();
			this.setActiveItem($galleryImg.closest('.gallery__item'));
			this.preloadImages();
			this.triggerItemChanged();

		}

	}

};

// If a service option has changed and the option has an image in the gallery, show that image
Slideshow.prototype.onServiceOptionChanged = function(e, data) {

	var galleryBlockId = utils.dom.getClosestBlockId(this.$gallery);
	var optionBlockId = utils.dom.getClosestBlockId(data.$option);
	var $galleryImg;

	// Check if this option has an associated image, and that this option and this gallery are from the same block
	if (data.imgId && galleryBlockId === optionBlockId) {

		// Search the gallery items for the image associated with the option
		$galleryImg = this.$items.find('.gallery__img').filter('[data-gallery-img-id="' + data.imgId + '"]');

		if ($galleryImg.length) {

			this.stopAutoPlay();
			this.setActiveItem($galleryImg.closest('.gallery__item'));
			this.preloadImages();
			this.triggerItemChanged();

		}

	}

};

Slideshow.prototype.onFormMutation = function() {

	if (this.focalPoint) {

		this.focalPoint.refreshParallax();

	}

};

Slideshow.prototype.onGalleryThumbChanged = function(e, data) {

	this.stopAutoPlay();
	this.injectItems();
	this.setActiveItem(this.$items.filter('[data-gallery-item-sequence="' + data.activeSequence + '"]'));
	// this.preloadImage(this.$activeItem);
	this.preloadImages();

	if (this.isCarouselStyle) {

		this.slide();

	}

};

Slideshow.prototype.onGalleryItemsFetched = function(e, data) {

	// Wait until current slide ends before processing and injecting to avoid visual jank from the non-transitioned slide that happens after injecting new items
	if (this.isCarouselStyle && this.isTransitioning) {

		this.$itemContainer.on('transitionend.gallery-items-fetched', function(evt) {

			// Make sure this event is from the sliding transition and not the caption fading transition
			if (this.$itemContainer.is(evt.target)) {

				// Remove handler so this never runs again
				this.$itemContainer.off('.gallery-items-fetched');
				this.onGalleryItemsFetched(e, data);

			}

		}.bind(this));

	} else {

		this.$queue = data.$items;
		this.injectItems();
		this.preloadImages();
		this.isWrapEnabled = true;
		this.toggleArrows();

	}

};

Slideshow.prototype.isFirstItem = function($item) {

	return $item.is(':first-child');

};

Slideshow.prototype.isLastItem = function($item) {

	return $item.is(':last-child');

};

Slideshow.prototype.userPrevious = function() {

	if (this.isTransitioning) {

		return;

	}

	this.stopAutoPlay();
	this.previous();

};

Slideshow.prototype.previous = function() {

	if (this.isFirstItem(this.$activeItem)) {

		if (this.isWrapEnabled) {

			this.injectItems();
			this.setActiveItem(this.$items.last());
			this.preloadImages();

			if (this.isCarouselStyle) {

				this.lastSlideDirection = 'left';

				if (this.doesCarouselLoop) {

					this.reorderCarousel();
					// The last image should be visually appearing before the first, so just continue sliding
					this.slide();

				} else {

					// Do a hard slide back to the beginning
					this.slide(true);

				}

			}

			this.triggerItemChanged();

		}

	} else {

		this.injectItems();
		this.setActiveItem(this.$activeItem.prev());
		this.preloadImages();

		if (this.isCarouselStyle) {

			this.lastSlideDirection = 'left';
			this.reorderCarousel();
			this.slide();

		}

		this.triggerItemChanged();

	}

};

Slideshow.prototype.userNext = function() {

	if (this.isTransitioning) {

		return;

	}

	this.stopAutoPlay();
	this.next();

};

Slideshow.prototype.next = function() {

	if (this.isLastItem(this.$activeItem)) {

		if (this.isWrapEnabled) {

			this.injectItems();
			this.setActiveItem(this.$items.first());
			this.preloadImages();

			if (this.isCarouselStyle) {

				this.lastSlideDirection = 'right';

				if (this.doesCarouselLoop) {

					// The first image should be visually appearing after the last, so just continue sliding
					this.slide();

				} else {

					// Do a hard slide back to the beginning
					this.slide(true);

				}

			}

			this.triggerItemChanged();

		}

	} else {

		this.injectItems();
		this.setActiveItem(this.$activeItem.next());
		this.preloadImages();

		if (this.isCarouselStyle) {

			this.lastSlideDirection = 'right';
			this.slide();

		}

		this.triggerItemChanged();

	}

};

Slideshow.prototype.trackMouseMovement = function(e) {

	if (!this.hasMouseMovement) {

		this.hasMouseMovement = true;
		this.$gallery.addClass('gallery--has-mouse-movement');

	}

	if ($(e.target).hasClass('gallery__arrow')) {

		this.removeMouseMovementClass.cancel();

	} else {

		this.removeMouseMovementClass();

	}

};

module.exports = {
	create: create,
	get: get
};
