'use strict';

// Normally, we need to use scrollBlocker on touch devices since just setting overflow hidden did not work. However, it appears this issue has been fixed so we'll remove the scrollBlocker functionality. Note: if for some reason we need to turn scrollBlocker back on, there is an outstanding issue that would need fixed. Using scrollBlocker in it's current fashion does not work well when you combine it with sticky elements. A certain sequence of events disables and enables sticky elements, which changes the height of the page, which causes the old scroll position to no longer be valid. This results in the page "jumping" up an amount equal to the height of the sticky elements when the mobile menu layout is closed.
// var scrollBlocker = require('scroll-blocker');

var appDom = require('./app-dom');
// var utils = require('./utils/main');

var blockScrolling = function() {

	appDom.app.addClass('app--no-scroll');

	// We only need scrollBlocker for touch devices, just setting overflow hidden (via app--no-scroll) will work on desktop browsers
	// if (utils.supports.touch) {

	// 	scrollBlocker.block();

	// }

};

var unblockScrolling = function() {

	appDom.app.removeClass('app--no-scroll');

	// We only need scrollBlocker for touch devices, just setting overflow hidden (via app--no-scroll) will work on desktop browsers
	// if (utils.supports.touch) {

	// 	scrollBlocker.unblock();

	// }

};

var onMenuLayoutOpened = function() {

	blockScrolling();
	appDom.app.addClass('app--has-open-menu-layout');

};

var onMenuLayoutClosed = function() {

	unblockScrolling();
	appDom.app.removeClass('app--has-open-menu-layout');

};

var addEventHandlers = function() {

	appDom.document.on({
		'modal_opened cart_opened': blockScrolling,
		'modal_closed cart_closed': unblockScrolling,
		'menu_layout_opened': onMenuLayoutOpened,
		'menu_layout_closed': onMenuLayoutClosed
	});

};

var init = function() {

	addEventHandlers();

};

module.exports = {
	init: init
};
