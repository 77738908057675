'use strict';

// Changelog
// 1. Replace deprecated domCache.triggerCustomEvent with trigger-custom-event system module
// 2. Trigger the changed event on the target element rather than always on document

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var throttle = require('lodash/function/throttle');
// var debounce = require('lodash/function/debounce');

var domCache = require('dom-cache');
var triggerCustomEvent = require('trigger-custom-event');

// Give each instance of this module a unique id.
var instanceId = 0;
// Store instance data privately so it can't be accessed/modified.
var instanceData = {};
var config = {
	target: 'body',
	eventName: 'device_state_changed',
	wait: 500
};

var getState = function() {

	var computedStyle = window.getComputedStyle(instanceData[this.id].target, '::before');
	var state = computedStyle.getPropertyValue('content');

	// Strip any quotes due to firefox bug.
	return state.replace(/'|"/g, '');

};

var saveState = function(state) {

	instanceData[this.id].lastState = state || this.getState();

};

var addEventHandlers = function() {

	domCache.window.on('resize.state-detection-' + this.id, throttle(function() {

		var data = instanceData[this.id];
		var lastState = data.lastState;
		var state = getState.call(this);
		var $target;

		console.log( 'resize for id: ' + this.id );

		if (state !== data.lastState) {

			console.log( 'state change for id:', this.id, state );

			saveState.call(this, state);

			$target = $(data.target);

			triggerCustomEvent(data.eventName, {
				target: $target,
				data: {
					$target: $target,
					oldState: lastState,
					newState: state
				}
			});

		}

	}, instanceData[this.id].wait).bind(this));

};

var StateDetection = function(opts) {

	var data;

	// Allow instantiation without the new keyword.
	if (this instanceof StateDetection) {

		Object.defineProperty(this, 'id', {value: instanceId++});

		// Keep all instance data private, except for the 'id', which will
		// be the key to get the private data for a specific instance.

		data = instanceData[this.id] = {
			lastState: ''
		};

		$.extend(data, config, opts);

		data.target = document.querySelector(data.target);

		addEventHandlers.call(this);
		saveState.call(this);

	} else {

		return new StateDetection(opts);

	}

};

StateDetection.prototype.destroy = function() {

	domCache.window.off('resize.state-detection-' + this.id);
	delete instanceData[this.id];

};

StateDetection.prototype.getState = getState;

module.exports = StateDetection;
