'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var lodashSortBy = require('lodash/collection/sortBy');

var StateDetection = require('state-detection-2.0.0');
var triggerCustomEvent = require('trigger-custom-event');

var pinboardLoadMore = require('./pinboard-load-more');
var appDom = require('../app-dom');
var utils = require('../utils/main');

var mobileTouchStartEventListener = require('./images/mobile-touchstart-listener');

var pinboardLookup = {};

var onPinboardStateChanged = function(e, data) {

	var blockId = utils.dom.getBlockId(utils.dom.closestBlock(data.$target));

	sortItems(blockId, 'change');

};

var onPinboardItemsLoaded = function(e, data) {

	var blockId = utils.dom.getBlockId(utils.dom.closestBlock(data.$pinboard));
	// Filter out any text nodes created from whitespace in the response markup
	var $pinboardItems = $(data.markup).not('text');

	sortItems(blockId, 'add', $pinboardItems);

};

var sortItems = function(blockId, type, $pinboardItems) {

	var pinboard = pinboardLookup[blockId];
	var $pinboard = pinboard.$pinboard;
	// Make a clone so all of our sorting is done in memory, not the actual dom
	var $pinboardClone = $pinboard.clone();
	var numCols = parseInt(pinboard.state.getState(), 10);
	var $sortedCols;
	var $sortedCol;
	var curCol;
	var $loadMoreItem;
	var loadMoreCol;

	$pinboard.removeClass('pinboard-layout--is-sorted');

	if (type === 'initial') {

		// This is our initial sort, simply grab the pinboard items from the clone, they will already be in order
		$pinboardItems = utils.dom.findPinboardItem($pinboardClone);

	} else if (type === 'change') {

		// The number of columns has changed, get the items from the clone, but before we visually sort them, we must sort them by source order since the dom can no longer be relied upon
		$pinboardItems = utils.dom.findPinboardItem($pinboardClone);
		pinboard.cols = [];

		$pinboardItems.sort(function(a, b) {

			var aIndex = utils.dom.getPinboardItemIndex(a);
			var bIndex = utils.dom.getPinboardItemIndex(b);

			if (aIndex > bIndex) {

				return 1;

			}

			if (aIndex < bIndex) {

				return -1;

			}

			return 0;

		});

	}

	if (type === 'add') {

		// We are adding to the columns we already have, so grab the columns from the clone
		$sortedCols = utils.dom.findPinboardCol($pinboardClone);

		// Find the existing load more item
		$loadMoreItem = utils.dom.findLastItem($sortedCols);

		// Determine which column the load more item resides in
		loadMoreCol = utils.dom.getPinboardCol(utils.dom.closestPinboardCol($loadMoreItem));

		// Remove the height of the load more item from our running counter
		pinboard.cols[loadMoreCol].height = pinboard.cols[loadMoreCol].height - utils.dom.getAspectRatioPadding(utils.dom.findAspectRatio($loadMoreItem));

		// Remove the load more item from the dom
		$loadMoreItem.remove();

	} else {

		// Create cell element for each column we need, and inside each column will be a nested grid
		for (curCol = 0; curCol < numCols; curCol++) {

			$sortedCol = $('<div class="grid__cell" data-pinboard-col="' + curCol + '"><div class="grid grid--col-1 grid--gutter-' + utils.dom.getGridGutter($pinboard) + '"></div></div>');

			if (!curCol) {

				$sortedCols = $sortedCol;

			} else {

				$sortedCols = $sortedCols.add($sortedCol);

			}

		}

	}

	// If we need a true size measurement for each item, loop over the items in the actual dom and store their heights
	if (pinboard.isTrueMeasure) {

		utils.dom.findPinboardItem($pinboard).each(function() {

			pinboard.trueMeasureHeights['' + utils.dom.getPinboardItemIndex(this)] = $(this).height();

		});

	}

	$pinboardItems.each(function(i) {

		var $pinboardItem = $pinboardItems.eq(i);
		var itemIndex = utils.dom.getPinboardItemIndex($pinboardItem[0]);
		var $aspectRatio;
		var $aspectRatioImg;
		var targetCol;

		if (itemIndex < numCols) {

			targetCol = itemIndex;

			pinboard.cols[targetCol] = {
				col: targetCol,
				height: 0
			};

		} else {

			// Sort the columns to find the column with the smallest height, the current item should be moved to this column
			targetCol = lodashSortBy(pinboard.cols, 'height')[0].col;

		}

		// Move the item into the smallest column
		$sortedCols.eq(targetCol)
			.children()
			.append($pinboardItem);

		if (pinboard.isTrueMeasure) {

			pinboard.cols[targetCol].height += pinboard.trueMeasureHeights['' + itemIndex] + pinboard.gutterInt;

		} else {

			$aspectRatio = utils.dom.findAspectRatio($pinboardItem);
			$aspectRatioImg = utils.dom.findAspectRatioImg($aspectRatio);

			if (!$aspectRatioImg.attr('data-lazy-image') && !$aspectRatioImg.hasClass('lazy-image--loaded')) {

				// This image has been processed by the lazy load module, but the image hasn't downloaded yet. We need to "reset" it, so when everything gets resorted and lazy image refreshes itself, it will re-process the image.
				$aspectRatioImg
					.removeClass('scroll-watch-in-view scroll-watch-ignore')
					// TODO: make the image size dynamic based on current state
					.attr('data-lazy-image', $aspectRatioImg.attr('data-pinboard-img-src'));

			}

			// We will not factor text underneath images into the slotting math, we would have to wait for custom fonts to finish downloading to get a truly accurate measurement
			pinboard.cols[targetCol].height += utils.dom.getAspectRatioPadding($aspectRatio) + pinboard.gutterInt;

		}

	});

	// Remove old columns from the dom
	utils.dom.findChildCell($pinboard).remove();

	// Add new sorted columns to the dom and make visible if necessary
	$pinboard
		.append($sortedCols)
		.addClass('pinboard-layout--is-sorted');

	triggerCustomEvent('pinboard_sorted');

};

var init = function() {

	var $pinboards = utils.dom.findPinboardLayout(appDom.content);

	if ($pinboards.length) {

		pinboardLoadMore.init();

		$pinboards.each(function(i) {

			var $pinboard = $pinboards.eq(i);
			var blockId = utils.dom.getBlockId(utils.dom.closestBlock($pinboard));

			$pinboard.on({
				'pinboard_state_changed': onPinboardStateChanged,
				'pinboard_items_loaded': onPinboardItemsLoaded
			});

			pinboardLookup[blockId] = {
				blockId: blockId,
				$pinboard: $pinboard,
				state: new StateDetection({
					target: '#block_' + blockId + ' .pinboard-layout',
					eventName: 'pinboard_state_changed'
				}),
				gutterInt: utils.dom.getGridGutterInt($pinboard),
				cols: [],
				trueMeasureHeights: {},
				// Note: logic for true measurement does not support load more functionality, if load more is needed, true measurement logic will need enhanced
				isTrueMeasure: $pinboard.hasClass('pinboard-layout--true-measure')
			};

			sortItems(blockId, 'initial');

		});

		mobileTouchStartEventListener.init();

	}

};

module.exports = {
	init: init
};
