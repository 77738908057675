'use strict';

var mobileTouchLinkHandlingWithHoverEffect = function() {
	var imageContainer = document.querySelector('.image-animate');
	if (!imageContainer) return;

	imageContainer.addEventListener('touchstart', function(e) {
		var image = e.target.closest('a');

		if (image && image.querySelector('.imageOverlayAnimationWrap') || image && image.querySelector('.list-pinboard-layout__info')) {

			if(image.dataset.hoverShown) {
				return image.dataset.hoverShown = "false";
			}

			e.preventDefault();
			e.stopPropagation();

			image.dataset.hoverShown = "true";
		}
	}, { passive: false });
};

var init = function() {

	mobileTouchLinkHandlingWithHoverEffect();

};

module.exports = {
	init: init
};
