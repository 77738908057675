'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var dom = require('./dom');

var instanceMap = {};

var create = function(formId) {

	return instanceMap[formId] = new Fileupload(formId);

};

var get = function(formId) {

	return instanceMap[formId];

};

var Fileupload = function(formId) {

	var formIdForValidation = formId.split('_').join('').toLowerCase();

	this.formId = formId;
	this.domInstance = dom.get(formId);
	this.defaultText = this.domInstance.inputContainer.find('.fb-file-upload-info').html();

	this.domInstance.form
		.on({
			'reset': this.onReset.bind(this),
			'fbWidget-destroy': this.destroy.bind(this)
		})
		.on('click', '.fb-file-upload-container', this.onContainerClick.bind(this))
		.on('change', '.fb-file-upload', this.onFileChange.bind(this));

	// Rename the custom parsley validators to be specific to this instance of the form
	this.domInstance.form.find('.fb-file-upload').each(function() {

		var $fileInput = $(this);
		var fileUploadSelection = $fileInput.attr('data-parsley-fileuploadselection');
		var fileUploadSize = $fileInput.attr('data-parsley-fileuploadsize');
		var fileUploadType = $fileInput.attr('data-parsley-fileuploadtype');

		if (fileUploadSelection) {

			$fileInput
				.attr('data-parsley-fileuploadselection' + formIdForValidation, fileUploadSelection)
				.removeAttr('data-parsley-fileuploadselection');

		}

		if (fileUploadSize) {

			$fileInput
				.attr('data-parsley-fileuploadsize' + formIdForValidation, fileUploadSize)
				.attr('data-parsley-fileuploadsize' + formIdForValidation + '-message', $fileInput.attr('data-parsley-fileuploadsize-message'))
				.removeAttr('data-parsley-fileuploadsize')
				.removeAttr('data-parsley-fileuploadsize-message');

		}

		if (fileUploadType) {

			$fileInput
				.attr('data-parsley-fileuploadtype' + formIdForValidation, fileUploadType)
				.attr('data-parsley-fileuploadtype' + formIdForValidation + '-message', $fileInput.attr('data-parsley-fileuploadtype-message'))
				.removeAttr('data-parsley-fileuploadtype')
				.removeAttr('data-parsley-fileuploadtype-message');

		}

	});

};

Fileupload.prototype.destroy = function() {

	delete instanceMap[this.formId];

};

Fileupload.prototype.onContainerClick = function(e) {

	var $container = $(e.target).closest('.fb-file-upload-container');
	var $fieldContainer = this.domInstance.getFieldContainer($container);

	$fieldContainer.find('.fb-file-upload').click();

};

Fileupload.prototype.isFileSelected = function($fileInput) {

	return !!$fileInput[0].files.length;

};

Fileupload.prototype.clearNativeFile = function($fileInput) {

	$fileInput.val('');

};

Fileupload.prototype.getInfo = function($fileInput) {

	var $fieldContainer = this.domInstance.getFieldContainer($fileInput);

	return $fieldContainer.find('.fb-file-upload-info');

};

Fileupload.prototype.getFile = function($fileInput) {

	if (!$fileInput.is('.fb-file-upload')) {

		$fileInput = $fileInput.find('.fb-file-upload');

	}

	return $fileInput[0].files[0];

};

Fileupload.prototype.showFileName = function($fileInput) {

	this.getInfo($fileInput).html('(' + this.getFile($fileInput).name + ')');

};

Fileupload.prototype.isFileuploadType = function($col) {

	return $col.hasClass('fb-fileupload-type');

};

Fileupload.prototype.getVal = function($col, fileUploadIdx) {

	var val = '';
	var fileObject;
	var size = null;
	var $fileInput = $col.find('.fb-file-upload');
	var haveFile = this.isFileSelected($fileInput);

	if (haveFile) {

		fileObject = this.getFile($fileInput);
		val = fileObject.name;
		size = fileObject.size;

	}

	return {
		name: this.domInstance.getFieldTitleText($col),
		value: val,
		size: size,
		// Save an index pointer to the list of actual uploaded files.
		fileUploadIdx: haveFile ? fileUploadIdx : null
	};

};

Fileupload.prototype.validateFileSelection = function($fileInput) {

	if (this.isFileSelected($fileInput)) {

		this.showFileName($fileInput);
		return true;

	}

	this.clearFile($fileInput);
	return false;

};

Fileupload.prototype.clearFile = function($fileInput) {

	this.clearNativeFile($fileInput);
	this.clearCustomFile($fileInput);

};

Fileupload.prototype.clearCustomFile = function($fileInput) {

	this.getInfo($fileInput).html(this.defaultText);

};

Fileupload.prototype.validateFileSize = function($fileInput) {

	var maxSize = parseInt($fileInput.attr('data-max-file-size'), 10);

	// Convert size to bytes.
	maxSize = maxSize * 1024 * 1024;

	if (this.isFileSelected($fileInput)) {

		if (this.getFile($fileInput).size > maxSize) {

			this.clearFile($fileInput);
			return false;

		}

	}

	this.showFileName($fileInput);
	return true;

};

Fileupload.prototype.validateFileType = function($fileInput) {

	var acceptedTypes = $fileInput.attr('data-accepted-file-types');
	var file = this.getFile($fileInput);
	var fileType = file.name.split('.').pop();

	if (acceptedTypes.split(',').includes(fileType)) {

		this.showFileName($fileInput);

		return true;

	}

	this.clearFile($fileInput);

	return false;

};

Fileupload.prototype.onFileChange = function(e) {

	// Clear file info if user clicks cancel from file picker dialog.

	var $file = $(e.target).closest('.fb-file-upload');

	// Parsley has some issues validating this field after a UI reset, so
	// manually trigger a validation on every change event.
	$file.parsley().validate();

	if (!this.isFileSelected($file)) {

		this.clearFile($file);

	}

};

Fileupload.prototype.onReset = function() {

	var $fileInputs = this.domInstance.form.find('[type="file"]');

	// All browsers except IE reset file input fields when a form is
	// natively reset. To fixe IE, we have to manually reset the values.
	// Unfortunately, IE is also the only browser that fires change
	// events when programatically changing the value of file inputs.
	// This causes our validation to unnecessarily run and pop up required
	// alerts when resetting the form (e.g. after a successful submission).
	// To get around this, we need to remove the file inputs from the dom,
	// reset their values (thus removing any selected files), and then put
	// them back into the dom. Events will not fire on elements when they
	// are not in the dom.

	$fileInputs.each(function(i) {

		var $fileInput = $fileInputs.eq(i);
		var $fieldContainer = this.domInstance.getFieldContainer($fileInput);

		this.clearCustomFile($fileInput);

		$fileInput = $fileInput.detach();

		this.clearNativeFile($fileInput);

		$fieldContainer.append($fileInput);

	}.bind(this));

};

module.exports = {
	create: create,
	get: get
};
