'use strict';

var domCache = require('dom-cache');
var throttle = require('throttle');

var dom = require('./dom');

var instanceMap = {};

var create = function(formId, forceOneColMode) {

	return instanceMap[formId] = new Layout(formId, forceOneColMode);

};

var get = function(formId) {

	return instanceMap[formId];

};

var Layout = function(formId, forceOneColMode) {

	this.formId = formId;
	this.domInstance = dom.get(formId);
	this.forceOneColMode = forceOneColMode;

	domCache.window.on('resize.fb-layout-' + this.formId, throttle(this.adjust, 100).bind(this));
	this.domInstance.form.on('fbWidget-destroy', this.destroy.bind(this));

	this.adjust();

};

Layout.prototype.breakPoints = {
	// Once the form is at least this wide, move to two col mode.
	twoColMode: 450,
	// Once the form is at least this wide, unstack the header.
	noHeaderStack: 800,
	// Once the form is at least this wide, unstack the cutoff info.
	noCutoffStack: 400
};

Layout.prototype.destroy = function() {

	domCache.window.off('.fb-layout-' + this.formId);
	delete instanceMap[this.formId];

};

// Get the exact decimal width. Useful for when page is zoomed to avoid
// rounding issues.
Layout.prototype.getFormWidth = function() {

	return parseFloat(window.getComputedStyle(this.domInstance.inputContainer[0]).width);

};

Layout.prototype.adjustHeaderLayout = function(formWidth) {

	if (formWidth >= this.breakPoints.noHeaderStack) {

		this.domInstance.headerContainer.addClass('fb-header-multi-col-mode');

	} else {

		this.domInstance.headerContainer.removeClass('fb-header-multi-col-mode');

	}

};

Layout.prototype.adjustCutoffLayout = function(formWidth) {

	if (formWidth >= this.breakPoints.noCutoffStack) {

		this.domInstance.cutoffMeta.addClass('fb-cutoff-meta-multi-col-mode');

	} else {

		this.domInstance.cutoffMeta.removeClass('fb-cutoff-meta-multi-col-mode');

	}

};

Layout.prototype.adjustFormLayout = function(formWidth) {

	if (formWidth >= this.breakPoints.twoColMode && !this.forceOneColMode) {

		this.domInstance.form.removeClass('fb-one-col-mode');
		this.domInstance.form.addClass('fb-two-col-mode');

	} else {

		// Set mobile first view.

		this.domInstance.form.removeClass('fb-two-col-mode');
		this.domInstance.form.addClass('fb-one-col-mode');

	}

};

Layout.prototype.adjust = function() {

	var formWidth = this.getFormWidth();

	this.domInstance.triggerFormEvent('fbWidget-window-resize', {
		formWidth: formWidth
	});

	this.adjustHeaderLayout(formWidth);
	this.adjustCutoffLayout(formWidth);
	this.adjustFormLayout(formWidth);

	this.domInstance.triggerFormEvent('fbWidget-layout-adjusted');

};

module.exports = {
	create: create,
	get: get
};
