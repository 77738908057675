/*

NOTE: this method is deprecated, use native custom events for this functionality

https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Creating_and_triggering_events

*/

'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var $document = $(document);
var defaults = {
	target: $document,
	data: {}
};

var triggerCustomEvent = function(eventType, options) {

	var opts = $.extend(true, {}, defaults, options);

	console.log('trigger-custom-event.js: ' + eventType, opts);

	opts.target.triggerHandler(eventType, opts.data);

};

module.exports = triggerCustomEvent;
