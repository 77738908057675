'use strict';

var objFit = require('obj-fit');

var appDom = require('../../app-dom');

var setAspectRatio = function() {
	var iframes = document.querySelectorAll(".video-banner-layout.no-crop iframe");
	if (iframes.length <= 0) return;
	iframes.forEach( function(el) {
		var setRatio = function() {
			var ratio = el.width && el.height ? (el.width / 10) +'/'+(el.height / 10) : '16/9';
			el.style.setProperty('aspect-ratio', ratio);
			el.removeEventListener("load", setRatio, false);
		};
		if (document.body.contains(el)) {
			el.addEventListener("load", setRatio);
		}
	});
}

var init = function() {
	setAspectRatio();

	if (appDom.content.find('[data-video-block-obj-fit]').length) {

		objFit.create({
			dataAttributeName: 'video-block-obj-fit',
			target: '[data-video-block-obj-fit]'
		});

	}

};

module.exports = {
	init: init
};
