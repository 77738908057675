'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var scrollToElement = require('scroll-to-element');

var $document = $(document);
var defaults = {
	position: 'top',
	buffer: 15,
	classPrefix: 'notify',
	customClasses: '',
	type: 'error',
	animationDistance: 10,
	showClose: true,
	closeOnClick: false,
	target: '',
	container: '',
	scrollContainer: '',
	autoHide: false,
	autoHideDuration: 3000
};
var state = {
	$notification: '',
	opts: {},
	isNestedContainer: false,
	autoHideTimer: null
};

const getPositionSetting = function() {

	const customPosition = state.opts.target[0].dataset.notifyPosition;

	return customPosition ? customPosition : state.opts.position;

};

document.addEventListener('click', function(e) {

	const notifyHook = e.target.closest('[data-js-show-notify]');

	if (notifyHook) {

		// Get the show options out of the data attribute and show the notify message with them
		api.show(Object.assign({closeOnClick: true}, JSON.parse(notifyHook.dataset.jsShowNotify)));

	}

});

const api = {
	position: function(animate) {

		var targetCoords;
		var targetSize;
		var targetCenter;
		var notifyCoords = {};
		var notifySize = {
			w: 0,
			h: 0
		};
		var animationCss = {
			opacity: 1
		};
		var distance = animate ? state.opts.animationDistance : 0;

		if (this.isNotificationActive()) {

			const positionSetting = getPositionSetting();

			targetSize = {
				w: this.target.outerWidth(),
				h: this.target.outerHeight()
			};

			if (state.isNestedContainer) {

				// jQuery doesn't account for padding, border, or margin when
				// using .position()

				targetCoords = this.target.position();
				// Adjust for margin
				targetCoords.top += parseInt(this.target.css('marginTop'), 10);
				targetCoords.left += parseInt(this.target.css('marginLeft'), 10);

			} else {

				targetCoords = this.target.offset();

			}

			targetCoords.right = state.opts.container.outerWidth() - (targetCoords.left + targetSize.w);

			targetCenter = {
				x: targetCoords.left + targetSize.w / 2,
				y: targetCoords.top + targetSize.h / 2
			};

			notifySize.w = state.$notification.outerWidth();
			notifySize.h = state.$notification.outerHeight();

			if (positionSetting === 'top') {

				notifyCoords.top =
					targetCoords.top -
					notifySize.h -
					state.opts.buffer -
					distance;

				notifyCoords.left = targetCenter.x - notifySize.w / 2;

				animationCss.top = '+=' + distance;

			} else if (positionSetting === 'top-left') {

				notifyCoords.top =
					targetCoords.top -
					notifySize.h -
					state.opts.buffer -
					distance;

				notifyCoords.left = targetCoords.left;

				animationCss.top = '+=' + distance;

			} else if (positionSetting === 'top-right') {

				notifyCoords.top =
					targetCoords.top -
					notifySize.h -
					state.opts.buffer -
					distance;

				notifyCoords.right = targetCoords.right;

				animationCss.top = '+=' + distance;

			} else if (positionSetting === 'bottom') {

				notifyCoords.top =
					targetCoords.top +
					targetSize.h +
					state.opts.buffer +
					distance;

				notifyCoords.left = targetCenter.x - notifySize.w / 2;

				animationCss.top = '-=' + distance;

			} else if (positionSetting === 'bottom-left') {

				notifyCoords.top =
					targetCoords.top +
					targetSize.h +
					state.opts.buffer +
					distance;

				notifyCoords.left = targetCoords.left;

				animationCss.top = '-=' + distance;

			} else if (positionSetting === 'right') {

				notifyCoords.top = targetCenter.y - notifySize.h / 2;

				notifyCoords.left =
					targetCoords.left +
					targetSize.w +
					state.opts.buffer +
					distance;

				animationCss.left = '-=' + distance;

			} else if (positionSetting === 'left') {

				notifyCoords.top = targetCenter.y - notifySize.h / 2;

				notifyCoords.left =
					targetCoords.left -
					notifySize.w -
					state.opts.buffer -
					distance;

				animationCss.left = '+=' + distance;

			}

			if (state.isNestedContainer) {

				notifyCoords.top += state.opts.container[0].scrollTop;

			} else if (notifyCoords.left < 11) {

				notifyCoords.left = 11;

			}

			// Position and re-measure notify size to adjust for any text wrapping and window edge positioning
			state.$notification.css(notifyCoords);

			// some preliminary adjusting logic
			/*const notifyWidth = state.$notification.outerWidth();

			if (positionSetting === 'top-left' && state.$notification.offset().left + notifyWidth > document.documentElement.clientWidth) {

				// adjust
				state.$notification.css({
					left: 'auto',
					// right: this.target[0].getBoundingClientRect().right
					right: document.documentElement.clientWidth - this.target[0].getBoundingClientRect().right
				});

			}

			const notifyHeight = state.$notification.outerHeight();

			if (notifyHeight !== notifySize.h) {

				notifyCoords.top -= (notifyHeight - notifySize.h);

				state.$notification.css('top', notifyCoords.top);

			}*/

			state.$notification.addClass(state.opts.classPrefix + '-positioned');

			// this.scrollIntoView();

			if (animate) {

				// state.$notification.css(animationCss);
				state.$notification.animate(animationCss);

			}

		}

	},

	show: function(options) {

		var instance = this;

		this.hide();

		state.opts = $.extend({}, defaults, options || {});

		if (!state.opts.target.jquery) {

			state.opts.target = $(state.opts.target);

		}

		state.$notification = $('<div class="' + state.opts.classPrefix + '-container"><div class="' + state.opts.classPrefix + '-close-container"><span class="' + state.opts.classPrefix + '-close">x</span></div><span class="' + state.opts.classPrefix + '-msg">' + this.msg + '</span></div>');

		if (state.opts.container) {

			state.opts.container = $(state.opts.container);
			state.isNestedContainer = true;

		} else {

			state.opts.container = $('body');
			state.isNestedContainer = false;

		}

		state.opts.container.append(state.$notification.addClass(state.opts.classPrefix + '-' + getPositionSetting() + ' ' + state.opts.classPrefix + '-' + state.opts.type + ' ' + (state.opts.showClose ? state.opts.classPrefix + '-show-close' : '') + ' ' + state.opts.customClasses));

		this.position(true);

		this.scrollIntoView();

		state.$notification.find('.notify-close-container').on('click', this.hide.bind(this));

		if (state.opts.closeOnClick) {

			// Add one time click handler to auto close, but only after the current execution finishes, otherwise the click event that triggered the notification would eventually bubble up to the document and execute this click handler
			setTimeout(function() {

				$document.one('click.notify-close-on-click', function() {

					instance.hide();

				});

			}, 0);

		}

		if (state.opts.autoHide) {

			clearTimeout(state.autoHideTimer);
			state.autoHideTimer = setTimeout(this.hide.bind(this), state.opts.autoHideDuration);

		}

		return state.$notification;

	},

	hide: function() {

		if (this.isNotificationActive()) {

			clearTimeout(state.autoHideTimer);
			$document.off('.notify-close-on-click');
			state.$notification.remove();

		}

		state.$notification = '';
		state.opts = {};

	},

	scrollIntoView: function(opts) {

		// state.$notification[0].scrollIntoView();

		/*

		scrollTopOffset: number of pixels to offset scrollTop position, relative to elementSelector - default 15
		scrollLeftOffset: number of pixels to offset scrollLeft position, relative to elementSelector - default 15
		speed: number of ms for scroll animation - default 0

		*/

		opts = opts || {};
		opts.speed = opts.speed || 0;
		opts.scrollTopOffset = typeof opts.scrollTopOffset === 'undefined' ? 15 : opts.scrollTopOffset;
		opts.scrollLeftOffset = typeof opts.scrollLeftOffset === 'undefined' ? 15 : opts.scrollLeftOffset;

		return scrollToElement({
			container: state.opts.scrollContainer,
			element: state.$notification,
			speed: opts.speed,
			scrollTopOffset: opts.scrollTopOffset,
			scrollLeftOffset: opts.scrollLeftOffset
		});

	},

	isNotificationActive: function() {

		return !!state.$notification.length;

	},

	isNotificationTarget: function($element) {

		return this.isNotificationActive() && $element.is(this.target);

	},

	get msg() {

		return state.opts.msg;

	},

	get target() {

		return state.opts.target;

	}
};

module.exports = api;
