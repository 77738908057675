'use strict';

var appDom = require('../../app-dom');
var mobileTouchStartEventListener = require('./mobile-touchstart-listener');

var init = function() {

	if (appDom.document.find('.imageOverlayAnimationWrap').length) {
		mobileTouchStartEventListener.init();
	}

};

module.exports = {
	init: init
};
