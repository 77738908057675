'use strict';

var appDom = require('./app-dom');
var modal = require('./modal');

var dom;

var addEventHandlers = function() {

	appDom.document.on('click', '.js-show-search', onShowSearchClick);

};

var getMarkup = function() {

	return '<form class="search">' +
				'<div class="search__content">' +
					'<span class="search__icon js-submit-search sites-icon sites-icon--search"></span>' +
					'<input type="text" class="search__input">' +
				'</div>' +
			'</form>';

};

var onSearchIconClick = function() {

	dom.search.submit();

};

var onSearchSubmit = function(e) {

	e.preventDefault();

	window.location = '/search-results/' + dom.searchInput.val();

};

var onShowSearchClick = function(e) {

	e.preventDefault();

	modal.open({
		content: getMarkup(),
		closeOnOverlayClick: true
	}).done(onSearchModalOpened);

};

var onSearchModalOpened = function(data) {

	dom = {
		search: data.$content
	};

	dom.searchInput = dom.search.find('.search__input').focus();

	dom.search
		.on('click', '.js-submit-search', onSearchIconClick)
		.on('submit', onSearchSubmit);

};

var init = function() {

	if (appDom.document.find('.js-show-search').length) {

		addEventHandlers();

	}

};

module.exports = {
	init: init
};
