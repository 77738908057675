'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var notify = require('notify');
var triggerCustomEvent = require('trigger-custom-event');
var pbizServer = (typeof window !== "undefined" ? window.pbizServer : typeof global !== "undefined" ? global.pbizServer : null);

var appDom = require('../app-dom');

var dom;
var originalBtnText;

var setupDom = function() {

	dom = {};
	dom.archiveForm = appDom.document.find('.blog-detail__archive-form');
	dom.archiveMonth = dom.archiveForm.find('.blog-detail__archive-month--is-active');
	dom.archiveYear = dom.archiveForm.find('.blog-detail__archive-year');
	dom.goBtn = dom.archiveForm.find('.blog-detail__archive-go-btn');

};

var addEventHandlers = function() {

	dom.goBtn.on('click', onGoBtnClicked);
	dom.archiveYear.on('change', onYearChanged);

};

var onYearChanged = function() {

	dom.archiveMonth.removeClass('blog-detail__archive-month--is-active');

	dom.archiveMonth = dom.archiveForm.find('.blog-detail__archive-month--' + $(this).val());

	dom
		.archiveMonth
		.addClass('blog-detail__archive-month--is-active');

};

var onGoBtnClicked = function(e) {

	console.log('go btn clicked');

	e.preventDefault();

	window.location = '/blog-archive/' + dom.archiveMonth.val() + '/' + dom.archiveYear.val();

};

var init = function() {

	if (appDom.document.find('.blog-detail__archive-form')) {

		setupDom();

		addEventHandlers();

	}

};

module.exports = {
	init: init
};