'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

const pbizServer = (typeof window !== "undefined" ? window.pbizServer : typeof global !== "undefined" ? global.pbizServer : null);

var get = function(action, queryData) {

	var data = queryData || {};

	data.action = action;

	if (pbizServer.isMobileDeviceMocked) {
		data.mockMobile = 1;
	}

	return '/index.cfm?' + $.param(data);

};

module.exports = {
	get: get
};
