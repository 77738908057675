'use strict';

var cacheFactory = require('cache-factory');

module.exports = cacheFactory(function(defer, url) {

	// Create a new image to load our url.
	var image = new Image();

	var cleanUp = function() {

		image.onload = image.onerror = null;
	};

	// Cleanup after load, whether it worked or not.
	defer.always(cleanUp);

	image.onload = function() {

		// The image has successfully loaded. Resolve the deferred with the
		// url, which will be stored as a unique key in the cache factory.
		defer.resolve(url);

	};

	// Reject the deferred if the image didn't load.
	image.onerror = defer.reject;

	// Set the src to start the load process.
	image.src = url;

});
