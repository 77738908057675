'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var notify = require('notify');
var triggerCustomEvent = require('trigger-custom-event');
var pbizServer = (typeof window !== "undefined" ? window.pbizServer : typeof global !== "undefined" ? global.pbizServer : null);
var recaptcha = require('recaptcha');

var utils = require('../../utils/main');
var modal = require('../../modal');
var appDom = require('../../app-dom');

var dom;
var addToCartText;
var recaptchaWidgetId;

var setupDom = function() {

	dom = {};
	dom.bookingForm = appDom.document.find('.scheduler-detail__booking-form');

};

var addEventHandlers = function() {

	appDom.document.on('click', '.js-book-now-btn', onBookNowClicked);
	appDom.document.on('click', '.scheduler-modal__close-btn', onModalCloseBtnClicked);
	appDom.document.on('click', '.scheduler-detail__booking-form-add-to-cart-btn', onAddToCartBtnClicked);
	appDom.document.on('item_added_to_cart', resetBookingForm);
	appDom.document.on('booking_form_rendered', setupRecaptcha);
	// dom.bookingForm.on('submit', submitBookingForm);

};

var resetBookingForm = function(e, data) {

	console.log(arguments);
	console.log(data.$scheduler);
	console.log(data.$scheduler[0]);

	// data.$scheduler.find('.scheduler-detail__booking-form')[0].reset();

	dom.bookingForm.empty();
	window.scrollTo(0,0);

};

var onAddToCartBtnClicked = function(e) {

	var $addToCartBtn = $(this);
	var $scheduler = $addToCartBtn.parents('.scheduler-detail');
	var $serviceForm = $scheduler.find('.scheduler-detail__service-form');
	var $bookingForm = $scheduler.find('.scheduler-detail__booking-form');
	var $scheduleBtn = $scheduler.find('.scheduler-detail__schedule-btn');
	var data = {};
	var serviceFormData = grabFormData($serviceForm.serializeArray());
	var bookingFormData = grabFormData($bookingForm.serializeArray());

	$.extend(data, serviceFormData);
	$.extend(data, bookingFormData);

	// not intended to be "var" (this is a module wide variable)
	addToCartText = $addToCartBtn.text();

	$addToCartBtn.text(pbizServer.content['Generic.Loading']);

	// add some custom goodies
	data['sku'] = $scheduleBtn.attr('data-sku') ? $scheduleBtn.attr('data-sku') : '';
	data['sourceUrl'] = $scheduleBtn.attr('data-item-url');
	data['itemId'] = $scheduleBtn.attr('data-item-id');
	data['itemType'] = 'scheduler';

	e.preventDefault();

	$.ajax({
		url: '/index.cfm?action=scheduler.processAddToCart',
		method: 'POST',
		dataType: 'JSON',
		data: data
	}).done(function(response) {

		onAddToCartLinkClickedDone(response, $scheduler);

	});

};


var onAddToCartLinkClickedDone = function(response, $scheduler) {

	$scheduler.find('.scheduler-detail__booking-form-add-to-cart-btn').text(addToCartText);

	if (response.status.code) {

		triggerCustomEvent('item_added_to_cart', {
			data: {
				$scheduler: $scheduler
			}
		});

	} else {

		notify.show({
			target: $scheduler.find('[name="' + response.target + '"]'),
			msg: response.status.msg,
			closeOnClick: true
		});

	}

};

var onBookNowClicked = function(e) {

	e.preventDefault();

	console.log('recaptchaWidgetId: ' + recaptchaWidgetId);

	// If this form supports recaptcha and we don't have a token yet, execute the challenge logic
	if (typeof recaptchaWidgetId !== 'undefined' && !window.grecaptcha.getResponse(recaptchaWidgetId)) {

		const $bookNowBtn = $(this);

		// Execute the recaptcha challenge, which will either immediately pass or present the user with a challenge to complete
		window.grecaptcha.execute(recaptchaWidgetId)
			.catch(function() {

				// An error in here most likely means the site key is incorrect

				notify.show({
					msg: pbizServer.content['Generic.UnexpectedError'] + ' (EC7.6)',
					target: $bookNowBtn,
					container: dom.bookingForm,
					closeOnClick: true
				});

			});

	} else {

		submitBookingForm();

	}

};

var onModalCloseBtnClicked = function() {

	window.location.reload();

};

var grabFormData = function(formArray) {

	var returnArray = {};

	for (var i = 0; i < formArray.length; i++){
		returnArray[formArray[i]['name']] = formArray[i]['value'];
	}

	return returnArray;

};

var submitBookingForm = function() {

	console.log('booking form submitted');
	console.log($(this));

	var data = grabFormData(dom.bookingForm.serializeArray());
	var $serviceForm = dom.bookingForm.parents('.scheduler-detail').find('.scheduler-detail__service-form');
	var $options = $serviceForm.find('[name^="optionID_"],[name^="option_custom_"]');

	$options.each(function(i, el) {
		data[$(el).attr('name')] = $(el).val();
	});

	$.ajax({
		url: utils.getUrl('scheduler.processBooking'),
		type: 'post',
		data: data
	}).done(function(response) {

		onBookingFormSubmittedDone(response, dom.bookingForm);

	});

};

var onBookingFormSubmittedDone = function(response, $bookingForm) {

	// default the "target" field to the first visible
	var $target = $bookingForm.find('input:visible').eq(0);

	// if target was explicitly provided, use the provided target
	if (response.target) {
		$target = $bookingForm.find('[name="' + response.target + '"]');
	}

	if (response.status.code) {

		modal.open({
			url: utils.getUrl('scheduler.bookSuccess'),
			data: {
				appointmentDate: response.appointmentDate,
				notificationSubject: response.notificationSubject,
				notificationMessage: response.notificationMessage,
				appointmentIdEncrypted: response.appointmentIdEncrypted
			}
		}).done(function() {

			// Handle scenario where user closes the modal via the modal "X" button
			appDom.document.one('modal_closed', onModalCloseBtnClicked);

		});

		resetRecaptcha();

	} else {

		if (response.status.detail === 'RECAPTCHA_VERIFICATION_ERROR') {

			resetRecaptcha();

		}

		$target.focus();

		notify.show({
			msg: response.status.msg,
			target: $target,
			container: $bookingForm,
			closeOnClick: true
		});

	}

};

var resetRecaptcha = function() {

	if (typeof recaptchaWidgetId !== 'undefined') {

		window.grecaptcha.reset(recaptchaWidgetId);

	}

};

var setupRecaptcha = function() {

	console.log('****** THE BOOKING FORM HAS BEEN RENDERED. ******');

	var $grecaptcha = dom.bookingForm.find('.g-recaptcha');

	if ($grecaptcha.length) {

		console.log('****** yep, go head and SET UP THE DAMN RECAPTCHA. ******')

		// This form should use recaptcha, so set it up
		recaptcha.init(pbizServer.languageTag)
			.then(function() {

				recaptchaWidgetId = window.grecaptcha.render($grecaptcha[0], {
					callback: submitBookingForm
				}, true);

			})
			.catch(console.warn);

	}

};

var init = function() {

	setupDom();

	addEventHandlers();

};

module.exports = {
	init: init
};
