'use strict';

var menu = require('./menu');
var menuLayoutTrigger = require('./menu-layout-trigger');

var init = function() {

	menu.init();
	menuLayoutTrigger.init();

};

module.exports = {
	init: init
};
