'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var dom = require('./dom');

var instanceMap = {};

var create = function(formId) {

	return instanceMap[formId] = new Embed(formId);

};

var get = function(formId) {

	return instanceMap[formId];

};

var Embed = function(formId) {

	this.formId = formId;
	this.domInstance = dom.get(formId);

	this.removeEmptyEmbeds();
	this.parseCode();

	this.domInstance.form.on({
		'fbWidget-destroy': this.destroy.bind(this)
	});

};

Embed.prototype.destroy = function() {

	delete instanceMap[this.formId];

};

// It is possible for the user to add an embedded field via the builder,
// but never add the code. Remove any empty embed fields from the form.
Embed.prototype.removeEmptyEmbeds = function() {

	var $embedFields = this.domInstance.inputContainer.find('.fb-embed-type');

	$embedFields.each(function(i) {

		var $field = $embedFields.eq(i);

		if (!$field.children().length) {

			$field.closest('.fb-row').remove();

		}

	});

};

Embed.prototype.parseCode = function() {

	var $videoWrappers = this.domInstance.inputContainer.find('.fb-embed-wrapper');

	$videoWrappers.each(function(i/*, el*/) {

		// Fix for z-index issues.

		var $videoWrapper = $videoWrappers.eq(i);
		// jQuery .data does not work on object/embed elements
		var $embedCode = $($videoWrapper.attr('data-embed-code'));
		var iframeUrl;
		var queryParam;

		if ($embedCode.is('iframe')) {

			// Handle iFrame.

			// Strip Vimeo text links.

			/*if ($embedCode.length > 1) {

				// More markup than just iFrame was entered into the cpanel.

				$embedCode = $($embedCode[0]);

			}*/

			iframeUrl = $embedCode.attr('src');

			queryParam = (iframeUrl.indexOf('?') === -1 ? '?' : '&') + 'wmode=transparent';

			$embedCode.attr('src', iframeUrl + queryParam);

		} else {

			// Handle old school object>embed markup.

			$embedCode
				.prepend('<param name="wmode" value="transparent">')
				.find('embed')
					.attr('wmode', 'transparent');

		}

		$videoWrapper.html($embedCode);

	});

};

module.exports = {
	create: create,
	get: get
};
