'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var dom = require('./dom');
var settings = require('./settings');
var selectmenu = require('./selectmenu');

var instanceMap = {};

var create = function(formId) {

	return instanceMap[formId] = new CountryStateSync(formId);

};

var get = function(formId) {

	return instanceMap[formId];

};

var CountryStateSync = function(formId) {

	this.formId = formId;
	this.domInstance = dom.get(formId);
	this.settingsInstance = settings.get(formId);
	this.selectmenuInstance = selectmenu.get(formId);

	this.syncPrefilled();

	this.domInstance.form
		.on('change', '.fb-address [id$="country"]', this.onCountryChange.bind(this))
		.on('fbWidget-destroy', this.destroy.bind(this));

};

CountryStateSync.prototype.destroy = function() {

	delete instanceMap[this.formId];

};

CountryStateSync.prototype.populateStates = function($select, states) {

	// Retain the first option, as it could have title text and not the standard "Select"
	$select.html($select.children().first().add(states));

	this.domInstance.triggerFormEvent('fbWidget-address-states-populated', {
		$select: $select
	});

};

CountryStateSync.prototype.onCountryChange = function(e) {

	var $country = $(e.target).closest('.fb-address [id$="country"]');
	var $stateSelect = $country.closest('.fb-nested-col').find('select[id$="state"]');
	var country = $country.val();

	$.ajax({
		type: 'get',
		cache: true,
		url: this.settingsInstance.statesUrl + '&c=' + country,
		dataType: 'html',
		context: this
	}).done(function(response) {

		this.populateStates($stateSelect, response);

	});

};

CountryStateSync.prototype.syncPrefilled = function() {

	// Attempt to sync any prefilled country and state dropdowns.

	this.selectmenuInstance.sync(this.domInstance.form.find('.fb-address [id$="country"], .fb-address [id$="state"]'));

};

module.exports = {
	create: create,
	get: get
};
