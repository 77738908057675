'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var domCache = require('dom-cache');
var triggerCustomEvent = require('trigger-custom-event');

var instanceMap = {};

var create = function(formId) {

	return instanceMap[formId] = new Dom(formId);

};

var get = function(formId) {

	return instanceMap[formId];

};

var Dom = function(formId) {

	$.extend(this, domCache);

	this.formId = formId;
	this.form = this.body.find('#' + formId);
	this.headerContainer = this.form.find('.fb-header-container');
	this.title = this.form.find('.fb-title');
	this.cutoffMeta = this.form.find('.fb-cutoff-meta');
	this.availableSubmissions = this.cutoffMeta.find('.fb-cutoff-meta-available-submissions');
	this.inputContainer = this.form.find('.fb-form-input-container');
	this.submitBtnContainer = this.form.find('.fb-submit-btn-container');
	this.submitBtn = this.submitBtnContainer.find('.fb-submit-btn');
	this.submitBtnText = this.submitBtn.val();
	this.submissionContainer = this.form.find('.fb-submission-container');
	this.submissionHeader = this.submissionContainer.find('.fb-submission-header');
	this.submissionMsg = this.submissionContainer.find('.fb-submission-msg');
	this.submissionReceiptBtn = this.submissionContainer.find('.fb-submission-payment-receipt-btn');
	this.submissionDocumentViewBtn = this.submissionContainer.find('.fb-submission-document-view-btn');
	this.recaptcha = this.form.find('.g-recaptcha');

	this.refreshRowsAndCols();

	this.form.on({
		'fbWidget-familyfriends-field-added': this.refreshRowsAndCols.bind(this),
		'fbWidget-familyfriends-field-removed': this.refreshRowsAndCols.bind(this),
		'fbWidget-destroy': this.destroy.bind(this)
	});

};

Dom.prototype.destroy = function() {

	delete instanceMap[this.formId];

};

Dom.prototype.refreshRowsAndCols = function() {

	this.rows = this.inputContainer.find('.fb-row');
	this.cols = this.rows.find('.fb-col');

};

Dom.prototype.getFieldId = function($col) {

	return parseInt($col.attr('data-field-id'), 10);

};

Dom.prototype.getInstanceId = function($col) {

	return $col[0].hasAttribute('data-instance-id') ? parseInt($col.attr('data-instance-id'), 10) : '';

};

Dom.prototype.getFieldContainer = function($el) {

	return $el.closest('.fb-field-container');

};

Dom.prototype.getNestedFieldContainer = function($el) {

	return $el.closest('.fb-nested-field-container');

};

Dom.prototype.getFieldInputs = function($el) {

	return $el.find('.fb-field-input');

};

Dom.prototype.getFieldTitleText = function($el) {

	// Return the text of the FIRST field title we find

	var $label = $el.find('.fb-field-title label');
	var title = '';
	var $input;
	var placeholder;

	if ($label.length) {

		title = $label.html();

	} else {

		// The style of this form could be using "inside" or "with icon"

		$input = this.getFieldInputs($el);
		placeholder = $input.attr('placeholder');

		if (placeholder) {

			title = placeholder;

		} else {

			// Dealing with a select element, so need to get the title from the first option
			title = $input.find('option:first-child').html();

		}

		// Remove any required asterisk
		title = title.replace(/\*/g, '').trim();

	}

	return title;

};

Dom.prototype.getNestedFieldTitleText = function($input) {

	return this.getFieldTitleText(this.getNestedFieldContainer($input));

};

Dom.prototype.triggerFormEvent = function(name, data) {

	triggerCustomEvent(name, {
		target: this.form,
		data: data
	});

};

Dom.prototype.getAntiSpamFields = function() {

	return this.form.find('input[name^="formfield123456789"]');

};

Dom.prototype.resetSubmitBtnText = function() {

	this.submitBtn.val(this.submitBtnText);

};

Dom.prototype.enableSubmit = function() {

	this.submitBtn.removeAttr('disabled');

};

Dom.prototype.disableSubmit = function() {

	this.submitBtn.attr('disabled', '');

};

module.exports = {
	create: create,
	get: get
};
