'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var appDom = require('./app-dom');

var addEventHandlers = function() {

	appDom.app.on('click', '[data-promo-link]', onPromoLinkClicked);

};

var onPromoLinkClicked = function() {

	var promoId = $(this).attr('data-promo-link');

	$.ajax({
		url: '?promotions=promotion.renderPromotion',
		method: 'POST',
		dataType: 'html',
		data: {
			'promotionId': promoId,
			'provideIframe': true
		}
	}).done(onPromoLinkClickedDone);

};

var onPromoLinkClickedDone = function(response) {

	appDom.app.append(response);

};

var init = function() {

	addEventHandlers();

};

module.exports = {
	init: init
};
