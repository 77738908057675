'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var triggerCustomEvent = require('trigger-custom-event');
var pbizServer = (typeof window !== "undefined" ? window.pbizServer : typeof global !== "undefined" ? global.pbizServer : null);

var appDom = require('../app-dom');
var utils = require('../utils/main');

var onLoadMorePinboardItemsClickDone = function($pinboard, prevNumItemsRendered, response) {

	console.log('onLoadMorePinboardItemsClickDone...', response);

	// If this block has been updated to support data-num-items-rendered, update it's value
	if ($pinboard.attr('data-num-items-rendered')) {

		$pinboard.attr('data-num-items-rendered', prevNumItemsRendered + response.numItemsRendered);

	}

	// For layouts that need to handle adding more items on their own (only pinboard at the moment), we'll fire this event, providing the markup for the new items
	triggerCustomEvent('pinboard_items_loaded', {
		target: $pinboard,
		data: {
			$pinboard: $pinboard,
			markup: response.markup
		}
	});

};

var onLoadMorePinboardItemsClick = function(e) {

	var $pinboard = $(this).closest('.pinboard-layout');
	var numItemsRendered = parseInt($pinboard.attr('data-num-items-rendered'), 10);
	var $block = utils.dom.closestBlock($pinboard);
	var isMockedBlock = utils.dom.isMockedBlock($block);
	var data = {
		numItemsRendered: 0,
		blockFriendlyName: utils.dom.getBlockFriendlyName($block),
		drilldownBlockCount: pbizServer.drilldownBlockCount,
		isInPageContext: pbizServer.isInPageContext,
		pageFriendlyName: pbizServer.pageFriendlyName
	};

	// If this block hasn't been updated to support the data-num-items-rendered attribute, get the number by counting the grid cells
	if (!numItemsRendered) {

		// Subtract 1 because we don't want to count the load more item
		numItemsRendered = utils.dom.findPinboardItem($pinboard).length - 1;

	}

	data.numItemsRendered = numItemsRendered;

	// Support load more for a mocked block on a system page
	if (isMockedBlock) {

		// Assuming that the only mocked blocks that support load more functionality are "pointer" blocks
		data.blockType = utils.dom.getBlockType($block);
		data.resourceTypeKey = utils.dom.getBlockResourceType($block);
		data.resourceIds = utils.dom.getBlockResourceIds($block);
		data.layoutRecordKey = utils.dom.getBlockLayoutKey($block);

	} else {

		data.blockId = utils.dom.getBlockId($block);

	}

	e.preventDefault();

	$.ajax({
		method: 'POST',
		url: utils.getUrl('block.loadMore'),
		data: data,
		dataType: 'json'
	}).done(function(response) {

		onLoadMorePinboardItemsClickDone($pinboard, numItemsRendered, response);

	});

};

var addEventHandlers = function() {

	appDom.content.on('click', '.js-load-more-pinboard-items', onLoadMorePinboardItemsClick);

};

var init = function() {

	addEventHandlers();

};

module.exports = {
	init: init
};
