'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var domCache = require('dom-cache');

var dom = {};

dom.app = $('#app');
dom.content = dom.app.find('#content');

if ('ontouchstart' in window) {

	dom.app.attr('data-touch', '');

}

// Extend the generic dom-cache with app specific elements
module.exports = $.extend(domCache, dom);
