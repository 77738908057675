'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var triggerCustomEvent = require('trigger-custom-event');

var appDom = require('../app-dom');

var dom;

var setupDom = function() {

	dom = {
		menuLayout: appDom.app.find('.menu-layout'),
		openTrigger: appDom.app.find('.js-open-menu-layout'),
		closeTrigger: appDom.app.find('.js-close-menu-layout')
	};

	dom.menuLayoutContent = dom.menuLayout.find('.menu-layout__content');

};

var addEventHandlers = function() {

	dom.openTrigger.on('click', openMenuLayout);
	dom.closeTrigger.on('click', closeMenuLayout);
	dom.menuLayout.on('click', onMenuLayoutClick);

};

var onMenuLayoutClick = function(e) {

	// Close the menu if it isn't full screen and you click outside of the menu
	if ($(e.target).is(dom.menuLayout) && dom.menuLayoutContent.width() < appDom.window.width()) {

		closeMenuLayout();

	}

};

var openMenuLayout = function() {

	dom.menuLayout.addClass('menu-layout--is-open');
	triggerCustomEvent('menu_layout_opened');

};

var closeMenuLayout = function() {

	dom.menuLayout.removeClass('menu-layout--is-open');
	triggerCustomEvent('menu_layout_closed');

};

var init = function() {

	setupDom();
	addEventHandlers();

};

module.exports = {
	init: init
};
