'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var lodashThrottle = require('lodash/function/throttle');
var lodashDebounce = require('lodash/function/debounce');

var appDom = require('./app-dom');

var dom;
var pendingScrollRAF = false;

var findStickyElement = function($sticky) {

	return $sticky.find('.sticky__element');

};

var stick = function($sticky) {

	var $stickyElement = findStickyElement($sticky);

	// if (doesStickyElementRetainWidth($stickyElement)) {

	// 	$stickyElement.css('width', $stickyElement.outerWidth());

	// }

	$sticky
		// Always get a fresh value from the dom, not the cache
		.css('height', $stickyElement.outerHeight(true))
		.addClass('sticky--is-stuck');

	$stickyElement.css('top', $.data($sticky[0], 'topOffset'));

	$.data($sticky[0], 'isStuck', 1);

};

var unStick = function($sticky) {

	var $stickyElement = findStickyElement($sticky);
	var $scrollTransition = $sticky.find('.sticky__scroll-transition');

	$sticky.removeClass('sticky--is-stuck');

	$stickyElement.css('top', '');

	if ($scrollTransition.length && $scrollTransition.css('transitionDuration') !== '0s') {

		$scrollTransition.one('transitionend', function() {

			$sticky.css('height', '');

		});

	} else {

		$sticky.css('height', '');

	}

	// if (doesStickyElementRetainWidth($stickyElement)) {

	// 	$stickyElement.css('width', '');

	// }

	$.data($sticky[0], 'isStuck', 0);

};

var isStickyVisible = function($sticky) {

	return $sticky.css('display') !== 'none';

};

var isStuck = function($sticky) {

	return $.data($sticky[0], 'isStuck');

};

// var doesStickyElementRetainWidth = function($stickyElement) {

// 	return typeof $stickyElement.attr('data-sticky-element-retain-width') !== 'undefined';

// };

var cacheData = function() {

	dom.sticky.each(function(i) {

		var $sticky = dom.sticky.eq(i);
		var $stickyElement;
		var topOffset;
		var stickyElementHeight;

		if (isStickyVisible($sticky)) {

			$stickyElement = findStickyElement($sticky);

			if (isStuck($sticky)) {

				stickyElementHeight = $stickyElement.outerHeight(true);

				if (parseInt($sticky[0].style.height, 10) !== stickyElementHeight) {

					// Something caused the height to change, so we need to update the inline height to match
					$sticky.css('height', stickyElementHeight);

				}

			}

			$.data(this, 'height', stickyElementHeight || $stickyElement.outerHeight(true));
			topOffset = getAllottedStickySpace($sticky);
			$.data(this, 'topOffset', topOffset);
			$.data(this, 'scrollOffset', this.offsetTop - topOffset);

		}

	});

};

// Determine how much space above the passed in sticky is taken up by other sticky elements
var getAllottedStickySpace = function($sticky) {

	var space = 0;

	dom.sticky.each(function(i) {

		var $curSticky = dom.sticky.eq(i);

		if ($curSticky.is($sticky) || !isStickyVisible($curSticky) || $curSticky[0].offsetTop >= $sticky[0].offsetTop) {

			return false;

		} else {

			space += $.data($curSticky[0], 'height');

		}

	});

	return space;

};

// Determine how much space above the passed in element is taken up by sticky elements
var getAllottedSpaceAboveElement = function(el, scrollPos) {

	var elOffsetTop = el.offsetTop;
	var space = 0;

	if (typeof scrollPos === 'undefined') {

		scrollPos = window.pageYOffset;

	}

	dom.sticky.each(function() {

		var $sticky = $(this);
		var $stickyElement = findStickyElement($sticky);

		if (isStickyVisible($sticky)) {

			// Check if this sticky is above the element and it doesn't extend the full height of the viewport
			if ($stickyElement[0].offsetTop < elOffsetTop && $stickyElement[0].offsetHeight < window.innerHeight) {

				// Check if the sticky is currently stuck or will become stuck once we go to a certain scroll position
				if (scrollPos >= $.data(this, 'scrollOffset')) {

					space += $.data(this, 'height');

				}

			}

		}

	});

	return space;

};

var checkPosition = function(forceStick) {

	dom.sticky.each(function(i) {

		var $sticky = dom.sticky.eq(i);

		if (isStickyVisible($sticky)) {

			if (!isPageAtTop() && window.pageYOffset >= $.data(this, 'scrollOffset')) {

				if (forceStick || !isStuck($sticky)) {

					stick($sticky);

				}

			} else {

				if (isStuck($sticky)) {

					unStick($sticky);

				}

			}

		} else {

			if (isStuck($sticky)) {

				unStick($sticky);

			}

		}

	});

};

var isPageAtTop = function() {

	return window.pageYOffset === 0;

};

// var refreshDataAtTop = function() {

// 	if (isPageAtTop()) {

// 		cacheData();

// 	}

// };

var refresh = function() {

	cacheData();
	// Pass in true to always call the stick() method, making sure the "top" property gets updated properly due to other sticky elements potentially being hidden now
	checkPosition(true);

};

var onScroll = function() {

	if (!pendingScrollRAF) {

		pendingScrollRAF = true;

		window.requestAnimationFrame(function() {

			pendingScrollRAF = false;

			// Refresh/sync up our data when scrolling to top to account for any layout changes
			// refreshDataAtTop();
			checkPosition();

		});

	}

};

var setupDom = function() {

	dom = {
		sticky: appDom.app.find('.sticky')
	};

};

var addEventHandlers = function() {

	appDom.window
		.on('scroll', lodashThrottle(onScroll, 10))
		// Account for layout changes from hidden/shown elements and thickness adjustments
		.on('resize', lodashDebounce(refresh, 100, {leading: true}))
		// Account for layout changes from loaded images, fonts, etc.
		.on('load', refresh);

	// If we load into a page that's not scrolled to the top, and an element inside the .sticky element has a scroll based transition that changes the height, the height of .sticky will be stuck (no pun intended) on the transitioned height, not the true original height that we want to actually take up space in the layout. To get around this edge case, we must flag such scenarios with a special class (.sticky__scroll-transition), so that we can reset the .sticky height to the appropriate size the first time we scroll back to the top of the page. This issue can also happen if you trigger a height change at one breakpoint, then resize your browser to a new breakpoint that uses different height values.
	// dom.sticky.each(function(i) {

	// 	var $curSticky = dom.sticky.eq(i);
	// 	var $scrollTransition = $curSticky.find('.sticky__scroll-transition');

	// 	if ($scrollTransition.length) {

	// 		$scrollTransition.on('transitionend', refreshDataAtTop);

	// 	}

	// });

};

var init = function() {

	setupDom();

	if (dom.sticky.length) {

		cacheData();
		addEventHandlers();
		checkPosition();

	}

};

module.exports = {
	init: init,
	getAllottedSpaceAboveElement: getAllottedSpaceAboveElement
};

// ScrollWatch example
/*

// on init
dom.markers.each(function(i) {

	var $marker = dom.markers.eq(i);
	var offset = getMarkerOffset($marker);

	new ScrollWatch({
		watch: '#' + $marker.attr('id'),
		inViewClass: 'sticky__marker--is-in-view',
		watchOnce: false,
		scrollThrottle: 10,
		onElementInView: onElementInView,
		onElementOutOfView: onElementOutOfView,
		watchOffsetYTop: offset === 0 ? 0 : -offset
	});

});

var onElementInView = function(data) {

	var $stickyMarker = $(data.el);
	var $sticky = $stickyMarker.closest('.sticky');
	var $stickyElement = $sticky.find('.sticky__element');

	if (data.eventType === 'scroll' && data.direction === 'up') {

		unStick($sticky, $stickyElement);

	}

};

var onElementOutOfView = function(data) {

	var $stickyMarker = $(data.el);
	var $sticky = $stickyMarker.closest('.sticky');
	var $stickyElement = $sticky.find('.sticky__element');

	if (
		(data.eventType === 'scrollwatchinit' && window.scrollY >= data.el.offsetTop)
		||
		(data.eventType === 'scroll' && data.direction === 'down')
	) {

		stick($sticky, $stickyMarker, $stickyElement);

	}
};

.sticky__marker {
	position: absolute;
	width: 1px;
	height: 1px;
	z-index: -9999;
	pointer-events: none;
	user-select: none;
}

.sticky__element--is-stuck {
	position: fixed;
	z-index: 99999;
}

<div class="sticky">
	<div id="menuLayoutOpenTriggerStickyMarker" class="sticky__marker"></div>
	<cfmodule
		template="#request.fw.appDir#/views/includes/menu/menu_layout_trigger.cfm"
		type="open"
		classes="u-hidden-large-up u-padding-thin sticky__element">
</div>

*/
