'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

// Cache factory pattern from MSDN post: http://msdn.microsoft.com/en-us/magazine/gg723713.aspx

module.exports = function(requestFunction, opts) {

	var cache = {};
	var options = opts || {};

	if (options.seed) {

		// Allow the caller to seed cache entries for resources already available.
		options.seed.forEach(function(obj) {

			cache[obj.key] = $.Deferred(function(defer) {

				defer.resolve.apply(defer, obj.args);

			}).promise();

		});

	}

	return function(key, callback) {

		if (!cache[key]) {

			// The resource isn't already available, so create a deferred
			// object and run the passed in requestFunction that will get the
			// resource.

			cache[key] = $.Deferred(function(defer) {

				requestFunction(defer, key);

			}).promise();

		}

		// Setup the passed in callback to run when the resource is available.
		// Return the promise so the caller can attach fail handlers or perform
		// more fine grained operations, if desired.

		return cache[key].done(callback);

	};

};
