'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var scrollToElement = require('scroll-to-element');

var dom = require('./dom');

var instanceMap = {};

var create = function(formId) {

	return instanceMap[formId] = new FamilyFriends(formId);

};

var get = function(formId) {

	return instanceMap[formId];

};

var FamilyFriends = function(formId) {

	this.formId = formId;
	this.domInstance = dom.get(formId);
	this.state = {
		cloneId: 0,
		cloneCnt: 0
	};

	this.domInstance.form
		.on('fbWidget-destroy', this.destroy.bind(this))
		.on('click', '.fb-ff-action', this.onActionClick.bind(this));

};

FamilyFriends.prototype.destroy = function() {

	delete instanceMap[this.formId];

};

FamilyFriends.prototype.isActionAdd = function($el) {

	return $el.attr('data-action') === 'add';

};

FamilyFriends.prototype.hideAddAction = function($el) {

	$el.find('[data-action="add"]').addClass('hide');

};

FamilyFriends.prototype.showAddAction = function($el) {

	$el.find('[data-action="add"]').removeClass('hide');

};

FamilyFriends.prototype.hideRemoveAction = function($el) {

	$el.find('[data-action="remove"]').addClass('hide');

};

FamilyFriends.prototype.showRemoveAction = function($el) {

	$el.find('[data-action="remove"]').removeClass('hide');

};

FamilyFriends.prototype.isFamilyfriendsType = function($col) {

	return $col.hasClass('fb-familyfriends-type');

};

FamilyFriends.prototype.getVal = function($col) {

	var $els = this.domInstance.getFieldInputs($col);
	var name = this.domInstance.getFieldTitleText($col);
	var vals = [];

	$els.each(function(i) {

		var $el = $els.eq(i);

		vals.push({
			name: this.domInstance.getNestedFieldTitleText($el),
			value: $el.val()
		});

	}.bind(this));

	return {
		name: name,
		value: vals
	};

};

FamilyFriends.prototype.getFamilyFriendsRows = function() {

	return this.domInstance.rows.has('.fb-familyfriends-type');

};

FamilyFriends.prototype.onActionClick = function(e) {

	var $action = $(e.target).closest('.fb-ff-action');
	var $row = $action.closest('.fb-row');
	var $clone;
	var $select;
	var $ffRows = this.getFamilyFriendsRows();
	var $lastRow = $ffRows.last();

	if (this.isActionAdd($action)) {

		// Add clone.

		$clone = $row.clone();
		$select = $clone.find('select');

		// Flag as a clone.
		$clone.addClass('fb-ff-clone');

		// Hide "add" action from cloned row.
		this.hideAddAction($row);

		if (this.state.cloneCnt === 0) {

			// Show "remove" action on cloned row.
			this.showRemoveAction($row);

			// Show "remove" action on clone.
			this.showRemoveAction($clone);

		}

		// Update title labels.
		$clone.find('label').each(function(i, el) {

			var $label = $(el);
			var forAttr = $label.attr('for');

			$label.attr('for', forAttr + '-clone-' + this.state.cloneId);

		}.bind(this));

		// Update form fields.
		$clone.find('input').add($select).each(function(i, el) {

			var $field = $(el);
			var oldAttr = $field.attr('id');
			var newAttr = oldAttr + '-clone-' + this.state.cloneId;

			$field
				.attr('id', newAttr)
				.attr('name', newAttr);

			// Make clones optional.
			// $this.prev().removeClass('fb-is-required');

			if (!$field.is('select')) {

				$field.val('');

			}

		}.bind(this));

		$clone
			.css({
				opacity: 0
			})
			.insertAfter($row);

		scrollToElement({
			element: $clone,
			force: true
		});

		$clone.animate({opacity: 1});

		this.domInstance.triggerFormEvent('fbWidget-familyfriends-field-added', {
			$row: $clone,
			$select: $select
		});

		this.state.cloneId++;
		this.state.cloneCnt++;

	} else {

		// Remove clone.

		this.domInstance.triggerFormEvent('fbWidget-familyfriends-field-remove', {
			$row: $row
		});

		$row.animate({opacity: 0}, function() {

			var removingLastRow = $row.is($lastRow);
			var $newLastRow = $lastRow;

			if (removingLastRow) {

				$newLastRow = $ffRows.eq(this.state.cloneCnt - 1);

			}

			// Show "add" action on new last row.
			this.showAddAction($newLastRow);

			if (this.state.cloneCnt === 1) {

				// Hide "remove" action when only one row.
				this.hideRemoveAction($newLastRow);

			}

			$row.remove();

			this.domInstance.triggerFormEvent('fbWidget-familyfriends-field-removed', {
				$row: $row
			});

			this.state.cloneCnt--;

		}.bind(this));

	}

};

module.exports = {
	create: create,
	get: get
};
