'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var notify = require('notify');
var scrollToElement = require('scroll-to-element');

var utils = require('../../utils/main');
var appDom = require('../../app-dom');
var sticky = require('../../sticky');

var addEventHandlers = function() {

	appDom.document.on('click', '.scheduler-detail__calendar-month-next-arrow', onNextArrowClicked);
	appDom.document.on('click', '.scheduler-detail__calendar-month-prev-arrow', onPrevArrowClicked);
	appDom.document.on('click', '.scheduler-detail__calendar-date-with-availability', onDateWithAvailabilityClicked);
	appDom.document.on('change', '.scheduler-detail__times-time', onTimeSelected);
	appDom.document.on('schedule_button_clicked', onScheduleBtnClicked);
	appDom.document.on('item_added_to_cart', resetCalendar);

};

var resetCalendar = function(e, data) {

	appDom.document.find('.scheduler-detail__date-time').empty();
	window.scrollTo(0,0);

};

var onScheduleBtnClicked = function(e, data) {

	console.log('onScheduleBtnClicked');

	loadCalendar({
		performScroll: true
	}, data.$serviceForm);

};

var onTimeSelected = function() {

	var $timeRadio = $(this);
	var $detail = $timeRadio.parents('.scheduler-detail');
	var $container = $detail.find('.scheduler-detail__times');
	var $serviceForm = $detail.find('.scheduler-detail__service-form');
	var $options = $serviceForm.find('[name^="optionID_"],[name^="option_custom_"]');

	var data = {
		appointmentDate: $container.attr('data-selected-date'),
		appointmentTime: $timeRadio.attr('data-appointment-time'),
		serviceId: $container.attr('data-service-id')
	};

	$options.each(function(i, el) {
		data[$(el).attr('name')] = $(el).val();
	});

	$.ajax({
		url: utils.getUrl('scheduler.bookingForm'),
		type: 'post',
		data: data
	}).done(function(response) {

		onRenderBookingFormDone(response, $detail.find('.scheduler-detail__booking-form'), $timeRadio);

	});

};

var onRenderBookingFormDone = function(response, $bookingForm, $timeRadio) {

	if (response.status.code) {

		$bookingForm.html(response.markup).show();

		appDom.document[0].dispatchEvent(new CustomEvent('booking_form_rendered', {}));

		scrollToElement({
			element: $bookingForm,
			scrollTopOffset: sticky.getAllottedSpaceAboveElement($bookingForm[0], $bookingForm[0].offsetTop),
			force: true
		});

	} else {

		notify.show({
			target: $timeRadio,
			msg: response.status.msg
		});

	}

};

var onDateWithAvailabilityClicked = function() {

	var selectedDate = $(this).attr('data-date');

	loadCalendar({
		selectedDate: selectedDate,
		calendarDate: selectedDate
	}, $(this).parents('.scheduler-detail').find('.scheduler-detail__service-form'));

};

var loadCalendar = function(options, $serviceForm) {

	var $schedulerDateTime = $serviceForm.parents('.scheduler-detail').find('.scheduler-detail__date-time');

	var args = {
		serviceId: $serviceForm.attr('data-item-id'),
		selectedDate: undefined,
		calendarDate: undefined,
		direction: undefined,
		performScroll: false
	};

	$.extend(args, options);

	$.ajax({
		url: utils.getUrl('scheduler.calendar'),
		type: 'post',
		data: args
	}).done(function(response) {
		onLoadCalendarDone(response, $schedulerDateTime, args.performScroll);
	});

};

var onLoadCalendarDone = function(response, $schedulerDateTime, performScroll) {

	console.log('on load calendar done called');

	$schedulerDateTime.html(response.markup);

	if (performScroll) {

		scrollToElement({
			element: $schedulerDateTime,
			scrollTopOffset: sticky.getAllottedSpaceAboveElement($schedulerDateTime[0], $schedulerDateTime[0].offsetTop),
			force: true
		});

	}

};

var onNextArrowClicked = function() {

	var $arrow = $(this);

	loadCalendar({
		selectedDate: $arrow.attr('data-selected-date'),
		calendarDate: $arrow.attr('data-calendar-date'),
		direction: 'next'
	}, $arrow.parents('.scheduler-detail').find('.scheduler-detail__service-form'));

};

var onPrevArrowClicked = function() {

	var $arrow = $(this);

	loadCalendar({
		selectedDate: $arrow.attr('data-selected-date'),
		calendarDate: $arrow.attr('data-calendar-date'),
		direction: 'prev'
	}, $arrow.parents('.scheduler-detail').find('.scheduler-detail__service-form'));

};

var init = function() {

	addEventHandlers();

};

module.exports = {
	init: init
};
