'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var notify = require('notify');

var appDom = require('./app-dom');

var onShareClicked = function(e) {

	var $shareBtn = $(this);
	var markup = '<div' +
					' class="notify-share__icons"' +
					' data-social-title="' + $shareBtn.attr('data-social-title') + '"' + 
					' data-social-description="' + $shareBtn.attr('data-social-description') + '"' + 
					' data-social-image-path="' + $shareBtn.attr('data-social-image-path') + '"' + 
					' data-social-return-url="' + $shareBtn.attr('data-social-return-url') + '">' + 
					 '<a href="#" data-network="facebook" class="notify-share__icon notify-share__icon--facebook sites-icon sites-icon--facebook"></a>' +
					 '<a href="#" data-network="twitter" class="notify-share__icon notify-share__icon--twitter sites-icon sites-icon--twitter"></a>' +
					 '<a href="#" data-network="pinterest" class="notify-share__icon notify-share__icon--pinterest sites-icon sites-icon--pinterest"></a>' + 
				 '</div>';

	notify.show({
		target: $shareBtn,
		msg: markup,
		position: 'bottom',
		type: 'share',
		customClasses: 'share-class',
		buffer: -40
	});

};

var onSocialNetworkIconClick = function(e) {

	var $icon = $(this);
	var $icons = $icon.parents('.notify-share__icons');
	var network = $icon.attr('data-network');
	var url = '/social/social_networking.cfm?type=default&networkID=';
	var title = $icons.attr('data-social-title');
	var description = $icons.attr('data-social-description');
	var imagePath = $icons.attr('data-social-image-path');
	var returnUrl = $icons.attr('data-social-return-url');

	e.preventDefault();

	if (network === 'facebook') {

		url += 'facebook';

	} else if (network === 'twitter') {

		url += 'twitter&text=' + title;

	} else if (network === 'pinterest') {

		url += 'pinterest&image_url=' + imagePath;

	}

	url += '&title=' + title;
	url += '&linkBack=' + returnUrl + '&ts=' + Date.now();

	console.log('**Social Share url: ');
	console.log(url);

	window.open(url, '_blank');

};

var init = function() {
		
	// register share icon click handler
	appDom.document.on('click', '.js-show-share-popup', onShareClicked);

	// register social network click handler
	appDom.document.on('click', '.notify-share__icon', onSocialNetworkIconClick);

};

module.exports = {
	init: init
};
