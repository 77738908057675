'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var dom = require('./dom');

var instanceMap = {};

var create = function(formId) {

	return instanceMap[formId] = new Option(formId);

};

var get = function(formId) {

	return instanceMap[formId];

};

var Option = function(formId) {

	this.formId = formId;
	this.domInstance = dom.get(formId);

	this.domInstance.form
		.on('fbWidget-destroy', this.destroy.bind(this))
		.on('change', '.fb-option-template input', this.onInputChange.bind(this))
		.on('change', '.fb-dropdown-type select', this.onSelectChange.bind(this));

	this.hideLinkedFields();

};

Option.prototype.destroy = function() {

	delete instanceMap[this.formId];

};

Option.prototype.triggerConditionalFieldsProcessedEvent = function() {

	this.domInstance.triggerFormEvent('fbWidget-conditional-fields-processed');

};

Option.prototype.onInputChange = function(e) {

	var $input = $(e.target).closest('.fb-option-template input');

	this.processLinkedFields($input);

	this.triggerConditionalFieldsProcessedEvent();

};

Option.prototype.onSelectChange = function(e) {

	var $select = $(e.target).closest('.fb-dropdown-type select');

	this.processLinkedFields($select.find(':selected'));

	this.triggerConditionalFieldsProcessedEvent();

};

Option.prototype.getLinkedToIds = function($target) {

	var linkObj = $target.data('linked-to-ids');

	return linkObj ? linkObj.linkIds : [];

};

Option.prototype.getLinkedFromIds = function($target) {

	var linkObj = $target.data('linked-from-ids');

	return linkObj ? linkObj.linkIds : [];

};

Option.prototype.getElementByLinkId = function(id) {

	return this.domInstance.rows.find('[data-link-id="' + id + '"]');

};

Option.prototype.getSelectedOption = function($col) {

	var $els;

	if (this.isRadioType($col) || this.isCheckboxType($col)) {

		$els = $col.find(':checked');

	} else {

		$els = $col.find(':selected');

	}

	return $els;

};

Option.prototype.getOptionCoords = function($option) {

	var id = $option[0].id;
	var parts = id.split('-');

	return {
		selector: '#' + id,
		type: parts[0],
		row: +parts[1] + 1,
		col: +parts[2] + 1,
		option: +parts[3] + 1
	};

};

Option.prototype.getExtraFees = function() {

	var extraFees = [];

	this.domInstance.form.find('.fb-option-field-with-fees :checked.fb-option-field-has-fee').each(function(i, el) {

		var $selectedOption = $(el);
		var optionCoords = this.getOptionCoords($selectedOption);

		extraFees.push({
			row: optionCoords.row,
			col: optionCoords.col,
			option: optionCoords.option
		});

	}.bind(this));

	return extraFees;

};

Option.prototype.isLinkableField = function($element) {

	return $element.hasClass('fb-option-template');

};

Option.prototype.isRadioBtn = function($element) {

	return $element.is('[type="radio"]');

};

Option.prototype.isRadioType = function($col) {

	return $col.hasClass('fb-radio-type');

};

Option.prototype.isCheckboxType = function($col) {

	return $col.hasClass('fb-checkbox-type');

};

Option.prototype.isSelectOption = function($element) {

	return $element.is('option');

};

Option.prototype.isOptionSelected = function($option) {

	var isSelected;

	if (this.isSelectOption($option)) {

		// Select box.
		isSelected = $option.prop('selected');

	} else {

		// Radio or checkbox.
		isSelected = $option.prop('checked');

	}

	return isSelected;

};

Option.prototype.isFieldVisible = function($target) {

	return !$target.hasClass('fb-linked-hide');

};

Option.prototype.isFieldHidden = function($target) {

	return !this.isFieldVisible($target);

};

Option.prototype.showLinkedField = function($target) {

	$target.removeClass('fb-linked-hide');

	this.domInstance.triggerFormEvent('fbWidget-linked-field-shown', {
		$target: $target
	});

};

Option.prototype.hideLinkedField = function($target, emitEvents) {

	$target.addClass('fb-linked-hide');

	if (emitEvents) {

		this.domInstance.triggerFormEvent('fbWidget-linked-field-hidden', {
			$target: $target
		});

	}

};

Option.prototype.hideLinkedFields = function() {

	// Get all the options that are linked to other fields.
	var $optionsWithLinks = this.domInstance.form.find('[data-linked-to-ids]');

	$optionsWithLinks.each(function(i) {

		var $option = $optionsWithLinks.eq(i);
		var linkIds = this.getLinkedToIds($option);

		// Hide all the fields linked to this option.
		$.each(linkIds, function(i, linkId) {

			var $linkedToField = this.getElementByLinkId(linkId);

			// Make sure the field actually exists in the dom. A previous bug did not always correctly clean up dead references in the data.
			if ($linkedToField.length) {

				this.hideLinkedField($linkedToField, false);

			}

		}.bind(this));

	}.bind(this));

};

Option.prototype.resetSelect = function($select) {

	$select.prop('selectedIndex', 0);

	this.domInstance.triggerFormEvent('fbWidget-dropdown-reset', {
		$select: $select
	});

};

Option.prototype.unSelect = function($option) {

	if (this.isSelectOption($option)) {

		this.resetSelect($option.closest('select'));

	} else {

		$option.prop('checked', false);

	}

};

Option.prototype.processLinkIds = function($option) {

	var linkedToIds = this.getLinkedToIds($option);

	// Loop over all the link Ids for this option.
	$.each(linkedToIds, function(i, linkedToId) {

		// Find the field with this link Id.
		var $linkedToField = this.getElementByLinkId(linkedToId);
		var linkedFromIds;
		var isOtherOptionSelected;
		var $selectedOption;

		// Make sure the field actually exists in the dom. A previous bug did not always correctly clean up dead references in the data.
		if ($linkedToField.length) {

			if (this.isOptionSelected($option)) {

				// Only show if it's currently hidden
				if (this.isFieldHidden($linkedToField)) {

					// Show the field linked to this option.
					this.showLinkedField($linkedToField);

					if (this.isLinkableField($linkedToField)) {

						// The field being shown could have linked options.
						// Look for selected options in this field and check
						// if they have links.

						$selectedOption = this.getSelectedOption($linkedToField);

						this.processLinkedFields($selectedOption);

					}

				}

			} else {

				// This option is not selected. Hide fields linked to this option,
				// but only if another selected option on the form isn't also
				// linked to it.

				// Get all options that link to the field being hidden.
				linkedFromIds = this.getLinkedFromIds($linkedToField);
				isOtherOptionSelected = false;

				// Check if other options linked to the field being hidden are
				// selected. If they are, we don't want to hide the field.
				$.each(linkedFromIds, function(i, linkedFromId) {

					var $option = this.getElementByLinkId(linkedFromId);

					if (this.isOptionSelected($option)) {

						isOtherOptionSelected = true;

						// Break out of jQuery each.
						return false;

					}

				}.bind(this));

				if (!isOtherOptionSelected) {

					// Only hide if it's currently visible
					if (this.isFieldVisible($linkedToField)) {

						this.hideLinkedField($linkedToField, true);

						if (this.isLinkableField($linkedToField)) {

							// The field being hidden could have linked options.
							// Look for selected options in this field and check
							// if they have links. Unselect the option to attempt
							// to hide all linked fields.

							$selectedOption = this.getSelectedOption($linkedToField);

							this.unSelect($selectedOption);

							this.processLinkedFields($selectedOption);

						}

					}

				}

			}

		}

	}.bind(this));

};

Option.prototype.processLinkedFields = function($option) {

	var $optionList;
	var $otherOptions;
	var isOptionRadio = this.isRadioBtn($option);
	var isOptionSelectOption = this.isSelectOption($option);

	this.processLinkIds($option);

	if (isOptionRadio || isOptionSelectOption) {

		// Get the other options in this group and process them.

		if (isOptionRadio) {

			$optionList = $option.closest('.fb-field-option-list');
			$otherOptions = $optionList.find('input').not($option);

		} else {

			$optionList = $option.closest('select');
			$otherOptions = $optionList.find('option').not($option);

		}

		$otherOptions.each(function(i) {

			this.processLinkIds($otherOptions.eq(i));

		}.bind(this));

	}

};

Option.prototype.isOptionType = function($col) {

	// Checking template type, since "option" can mean radio, checkbox
	// or dropdown type.
	return $col.hasClass('fb-option-template');

};

// Used to disable an option if it was available on form load, but not
// on form submission.
Option.prototype.disable = function($option) {

	var $target;
	var $select;

	this.unSelect($option);

	$option.attr('disabled', '');

	if (this.isSelectOption($option)) {

		$target = $option;

		// Reset the dropdown and trigger a change event so linked fields
		// will get processed.
		$select = $option.closest('select');
		this.resetSelect($select);
		$select.change();

	} else {

		$target = $option.next();

		this.processLinkedFields($option);

		this.triggerConditionalFieldsProcessedEvent();

	}

	$target.html($target.attr('data-unavailable-msg'));

};

Option.prototype.getVal = function($col) {

	var $els = this.getSelectedOption($col);
	var val = '';

	$els.each(function(i) {

		val += i ? ', ' : '';

		val += $els.eq(i).val();

	});

	return {
		name: this.domInstance.getFieldTitleText($col),
		value: val
	};

};

module.exports = {
	create: create,
	get: get
};
