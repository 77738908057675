'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var appDom = require('../app-dom');
var utils = require('../utils/main');

var dom;

var setupDom = function() {

	dom = {
		mainNav: appDom.app.find('.main-nav')
	};

};

var addEventHandlers = function() {

	var onDocumentClickSet = false;

	dom.mainNav.each(function(i) {

		var $mainNav = dom.mainNav.eq(i);

		// Provide a hover class for non touch devices, not using :hover in css because of the different ways mobile browsers attempt to translate :hover into clicks and to make it easier for templates to target this state
		if (!utils.supports.touch) {

			$mainNav
				.on('mouseenter mouseleave', '.js-toggle-main-nav-link-hover-state', toggleLinkHoverState)
				.on('mouseenter mouseleave', '.js-toggle-main-nav-sub-link-hover-state', toggleSubLinkHoverState);

		}

		$mainNav
			.on('click', '.js-select-main-nav-link', onSelectMainNavLinkClick)
			.on('click', '.js-select-main-nav-sub-link', selectSubLink);

		// Provide a dropdown state class for design flexibility to distinguish between a hovered link and a link with open dropdown
		if ($mainNav.hasClass('main-nav--click-dropdown-trigger') || utils.supports.touch) {

			if (!onDocumentClickSet) {

				onDocumentClickSet = true;
				appDom.document.on('click', onDocumentClick);

			}

			$mainNav.on('click', '.js-toggle-main-nav-link-dropdown-state', toggleLinkDropdownState);

		} else {

			$mainNav.on('mouseenter mouseleave', '.js-toggle-main-nav-link-dropdown-state', toggleLinkDropdownState);

		}

	});

};

var onDocumentClick = function(e) {

	// Close the dropdown if a click came from outside the menu
	if (!$(e.target).closest('.main-nav').length) {

		closeDropdown();

	}

};

var openDropdown = function($link) {

	closeDropdown();
	$link.addClass('main-nav__link--has-open-dropdown');

};

var closeDropdown = function($link) {

	$link = $link || dom.mainNav.find('.main-nav__link--has-open-dropdown');
	$link.removeClass('main-nav__link--has-open-dropdown');

};

var hasOpenDropdown = function($link) {

	return $link.hasClass('main-nav__link--has-open-dropdown');

};

var isLinkSelected = function($link) {

	return $link.hasClass('main-nav__link--is-selected');

};

var onSelectMainNavLinkClick = function() {

	selectLink($(this));

};

var selectLink = function($link) {

	dom.mainNav.find('.main-nav__link--is-selected').removeClass('main-nav__link--is-selected');
	$link.addClass('main-nav__link--is-selected');

};

var selectSubLink = function() {

	var $subLink = $(this);
	var $subLinkParent = $subLink.closest('.main-nav__item').find('.main-nav__link');

	if (!isLinkSelected($subLinkParent)) {

		// The parent link associated with this sub link is not currently selected, so make it selected
		selectLink($subLinkParent);

	}

	dom.mainNav.find('.main-nav__sub-link--is-selected').removeClass('main-nav__sub-link--is-selected');
	$(this).addClass('main-nav__sub-link--is-selected');

};

var toggleLinkHoverState = function() {

	$(this).toggleClass('main-nav__link--is-hovered');

};

var toggleSubLinkHoverState = function() {

	$(this).toggleClass('main-nav__sub-link--is-hovered');

};

var toggleLinkDropdownState = function() {

	var $link = $(this);

	// For hover menus, the trigger is not directly on the link
	if (!$link.hasClass('main-nav__link')) {

		$link = $link.children('.main-nav__link');

	}

	if (hasOpenDropdown($link)) {

		closeDropdown($link);

	} else {

		openDropdown($link);

	}

};

var init = function() {

	setupDom();
	addEventHandlers();

};

module.exports = {
	init: init
};
