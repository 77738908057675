'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var triggerCustomEvent = require('trigger-custom-event');
var pbizServer = (typeof window !== "undefined" ? window.pbizServer : typeof global !== "undefined" ? global.pbizServer : null);
var notify = require('notify');

var domCache = require('./app-dom');
var dom;

var setupDom = function() {

	dom = {};
	dom.cart = $('.cart');

};

var addEventHandlers = function() {

	domCache.document.on('click', '.cart__apply-coupon-btn', onApplyCouponBtnClicked);

};

var onApplyCouponBtnClicked = function(e) {

	var $input = $('.cart__coupon-input');

	e.preventDefault();

	$(this).text(pbizServer.content['Generic.Loading']);

	$.ajax({
		url: '/index.cfm?action=cart.applyCoupon',
		method: 'POST',
		dataType: 'JSON',
		data: {
			couponCode: $input.val()
		}
	}).done(onApplyCouponBtnClickedDone);

};

var onApplyCouponBtnClickedDone = function(response) {

	var $target;

	if (response.status.code) {

		triggerCustomEvent('coupon_applied');

		notify.hide();

	} else {

		$target = $('[name="' + response.target + '"]');

		notify.show({
			msg: response.status.msg,
			target: $target,
			container: '.cart__content'
		});

	}

	$('.cart__apply-coupon-btn').text(pbizServer.content['Generic.Apply']);

};

var init = function() {

	setupDom();

	addEventHandlers();

};

module.exports = {
	init: init
};
