'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var ScrollWatch = require('scrollwatch-2.0.1');

var loadImage = require('load-image');

var lazyImage = function(opts) {

	var swInstance;

	var options = {
		target: '[data-lazy-image]',
		throttleWait: 250,
		debounceWait: 250,
		debounce: false,
		watchOffsetXLeft: 0,
		watchOffsetXRight: 0,
		watchOffsetYTop: 0,
		watchOffsetYBottom: 0
	};

	var isBackgroundImg = function($img) {

		return $img.is('img') ? false : true;

	};

	var processImg = function($img) {

		var imgSrc = $img.attr('data-lazy-image');
		var isBackground = isBackgroundImg($img);

		$img.removeAttr('data-lazy-image');

		loadImage(imgSrc, function() {

			if (isBackground) {

				$img.css('background-image', 'url(' + imgSrc + ')');

			} else {

				$img.attr('src', imgSrc);

			}

			$img.addClass('lazy-image--loaded');

		});

	};

	var onElementInView = function(data) {

		processImg($(data.el));

	};

	var refresh = function() {

		console.log('lazy-load refresh()...');

		swInstance.refresh();

	};

	var destroy = function() {

		console.log('lazy-image.js: destroy()');

		if (swInstance) {

			swInstance.destroy();
			swInstance = null;

		}

	};

	var init = function() {

		var swOptions;

		$.extend(options, opts);

		swOptions = {
			watch: options.target,
			watchOffsetXLeft: options.watchOffsetXLeft,
			watchOffsetXRight: options.watchOffsetXRight,
			watchOffsetYTop: options.watchOffsetYTop,
			watchOffsetYBottom: options.watchOffsetYBottom,
			onElementInView: onElementInView
		};

		if (options.debounce) {

			swOptions.debounce = true;
			swOptions.scrollDebounce = options.debounceWait;
			swOptions.resizeDebounce = options.debounceWait;

		} else {

			swOptions.scrollThrottle = options.throttleWait;
			swOptions.resizeThrottle = options. throttleWait;

		}

		swInstance = new ScrollWatch(swOptions);

	};

	init();

	return {
		destroy: destroy,
		refresh: refresh
	};

};

module.exports = lazyImage;
