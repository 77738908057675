'use strict';

var lazyImage = require('lazy-image-3.0.0');

var appDom = require('./app-dom');

var lazyImageInstance;

var addEventHandlers = function() {

	appDom.document.on('grid_items_added pinboard_sorted', lazyImageInstance.refresh);

};

var init = function() {

	if (appDom.app.find('[data-lazy-image]').length) {

		// We have at least one image that uses lazy loading

		// Set up lazy loading for all images
		lazyImageInstance = lazyImage({
			watchOffsetYTop: 500,
			watchOffsetYBottom: 500
		});

		addEventHandlers();

	}

};

module.exports = {
	init: init
};
