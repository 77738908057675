'use strict';

var slideshow = require('./slideshow');
var thumbnails = require('./thumbnails');
var loadMore = require('./load-more');

var instanceMap = {};
var instanceCount = 0;

var create = function($gallery) {

	var galleryId = $gallery.attr('id');

	if (!galleryId) {

		galleryId = 'gallery_' + instanceCount++;
		$gallery.attr('id', galleryId);

	}

	return instanceMap[galleryId] = new Gallery($gallery, galleryId);

};

var Gallery = function($gallery, galleryId) {

	this.$gallery = $gallery;
	this.galleryId = galleryId;
	this.hasThumbs = this.$gallery.hasClass('gallery--thumbs');
	this.hasMoreItems = typeof this.$gallery.attr('data-gallery-has-more-items') !== 'undefined';

	this.slideshowInstance = slideshow.create(this.$gallery, this.galleryId, this.hasMoreItems);

	if (this.hasThumbs) {

		this.thumbnailsInstance = thumbnails.create(this.$gallery, this.galleryId);

	}

	if (this.hasMoreItems) {

		this.loadMoreInstance = loadMore.create(this.$gallery, this.galleryId);

	}

};

Gallery.prototype.destroy = function() {

	this.slideshowInstance.destroy();

	if (this.thumbnailsInstance) {

		this.thumbnailsInstance.destroy();

	}

	if (this.loadMoreInstance) {

		this.loadMoreInstance.destroy();

	}

	instanceMap[this.galleryId] = null;

};

module.exports = {
	create: create
};
